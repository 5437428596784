body {
    &.theme-dark {
        .text-gray-700 {
            color: $gray-500 !important;
        }
        .text-gray-800 {
            color: $purple-40 !important;
          }
        .text-dark {
            color: $white !important;
        }
        .text-gray-400 {
            color: $secondary-hover !important;
        }
    }
}

