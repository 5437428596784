//base
@import '../variables';
@import '../mixins';
@import 'global/global';
//Components
@import 'components/components';

//Uitilities
@import 'utilities/utilities';

//Pages
@import 'pages/pages';
body {
    &.theme-dark {
        .header {
            background-color: $purple-dark;
            color: $white;
            border-bottom: 1px solid $gray-dark;
        }
        .text-black {
            color: $purple-30 !important;
        }
        .invoice-brand {
            .in-brand {
                &.dark {
                    display: none;
                }
                &.light {
                    display: block;
                }
            }
        }
    }
}