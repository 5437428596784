.subscription-tiles {
    .card-header {
        border-bottom: 1px dashed $light-color;
    }
}

.invoice-brand {
    .in-brand {
        &.dark {
            display: block;
        }
        &.light {
            display: none;
        }
    }
}