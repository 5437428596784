.symbol {
    >img, .symbol-label {
        @include rounded(50px, 0.475rem);
    }
    .symbol-label {
        @include flex-combinator(row, wrap, center, center);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    &.symbol-40px {
        >img, .symbol-label {
            @include rounded(40px, 0.475rem);
        }
    }
    &.symbol-45px {
        >img, .symbol-label {
            @include rounded(45px, 0.475rem);
        }
    }
}