.card {
    position: relative;
    @include flexbox;
    @include flex-direction(column);
    min-width: 0;
    word-wrap: break-word;
    background-color: $white;
    background-clip: border-box;
    border-radius: .475rem;
    border: 0;
    // box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
    margin-bottom: 2rem;
    &.card-padding {
        padding: 25px 15px;
    }
    .card-header {
        margin: 0;
        padding: 15px 20px 15px 20px;
        border: 0;
        background-color: $white;
        @include flex-combinator(row, wrap, center, space-between);

        .card-title {
            @include flex-combinator(column, wrap, flex-start, flex-start);
        }
        .btn {
          min-height: auto;
      }
    }

    .card-body {
        padding: 2rem -.75rem;
    }

    .card-footer {
        padding: 2rem 2.25rem;
        background-color: transparent;
        border-top: 1px solid $gray-400;
    }
}
.card-rounded {
    border-radius: .475rem;
}
