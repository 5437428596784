//Sizing
.fs-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }
  .fs-4 {
    font-size: 1.25rem !important;
  }
  .fs-5 {
    font-size: 1.15rem !important;
  }
  .fs-6 {
    font-size: 1.075rem !important;
  }
  .fs-7 {
    font-size: 0.95rem !important;
  }
  .fs-8 {
    font-size: 0.85rem !important;
  }
  .fs-9 {
    font-size: 0.75rem !important;
  }
  .fs-10 {
    font-size: 0.5rem !important;
  }
  .fs-base {
    font-size: 1rem !important;
  }
  .fs-fluid {
    font-size: 100% !important;
  }
  .fs-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }
  .fs-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }
  .fs-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }
  .fs-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }
  .fs-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }
  .fs-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }
  .fs-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }
  .fs-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }
  .fs-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }
  .fs-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }
  .fs-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }
  .fs-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }
  .fs-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }
  .fs-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

.h-25px {
    height: 25px!important;
}

@media (min-width: 576px) {
    .fs-sm-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
      }
      .fs-sm-2 {
        font-size: calc(1.275rem + 0.3vw) !important;
      }
      .fs-sm-3 {
        font-size: calc(1.26rem + 0.12vw) !important;
      }
      .fs-sm-4 {
        font-size: 1.25rem !important;
      }
      .fs-sm-5 {
        font-size: 1.15rem !important;
      }
      .fs-sm-6 {
        font-size: 1.075rem !important;
      }
      .fs-sm-7 {
        font-size: 0.95rem !important;
      }
      .fs-sm-8 {
        font-size: 0.85rem !important;
      }
      .fs-sm-9 {
        font-size: 0.75rem !important;
      }
      .fs-sm-10 {
        font-size: 0.5rem !important;
      }
      .fs-sm-base {
        font-size: 1rem !important;
      }
      .fs-sm-fluid {
        font-size: 100% !important;
      }
      .fs-sm-2x {
        font-size: calc(1.325rem + 0.9vw) !important;
      }
      .fs-sm-2qx {
        font-size: calc(1.35rem + 1.2vw) !important;
      }
      .fs-sm-2hx {
        font-size: calc(1.375rem + 1.5vw) !important;
      }
      .fs-sm-2tx {
        font-size: calc(1.4rem + 1.8vw) !important;
      }
      .fs-sm-3x {
        font-size: calc(1.425rem + 2.1vw) !important;
      }
      .fs-sm-3qx {
        font-size: calc(1.45rem + 2.4vw) !important;
      }
      .fs-sm-3hx {
        font-size: calc(1.475rem + 2.7vw) !important;
      }
      .fs-sm-3tx {
        font-size: calc(1.5rem + 3vw) !important;
      }
      .fs-sm-4x {
        font-size: calc(1.525rem + 3.3vw) !important;
      }
      .fs-sm-4qx {
        font-size: calc(1.55rem + 3.6vw) !important;
      }
      .fs-sm-4hx {
        font-size: calc(1.575rem + 3.9vw) !important;
      }
      .fs-sm-4tx {
        font-size: calc(1.6rem + 4.2vw) !important;
      }
      .fs-sm-5x {
        font-size: calc(1.625rem + 4.5vw) !important;
      }
      .fs-sm-5qx {
        font-size: calc(1.65rem + 4.8vw) !important;
      }
      .fs-sm-5hx {
        font-size: calc(1.675rem + 5.1vw) !important;
      }
      .fs-sm-5tx {
        font-size: calc(1.7rem + 5.4vw) !important;
      }
}

@media (min-width: 768px) {
    .fs-md-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
      }
      .fs-md-2 {
        font-size: calc(1.275rem + 0.3vw) !important;
      }
      .fs-md-3 {
        font-size: calc(1.26rem + 0.12vw) !important;
      }
      .fs-md-4 {
        font-size: 1.25rem !important;
      }
      .fs-md-5 {
        font-size: 1.15rem !important;
      }
      .fs-md-6 {
        font-size: 1.075rem !important;
      }
      .fs-md-7 {
        font-size: 0.95rem !important;
      }
      .fs-md-8 {
        font-size: 0.85rem !important;
      }
      .fs-md-9 {
        font-size: 0.75rem !important;
      }
      .fs-md-10 {
        font-size: 0.5rem !important;
      }
      .fs-md-base {
        font-size: 1rem !important;
      }
      .fs-md-fluid {
        font-size: 100% !important;
      }
      .fs-md-2x {
        font-size: calc(1.325rem + 0.9vw) !important;
      }
      .fs-md-2qx {
        font-size: calc(1.35rem + 1.2vw) !important;
      }
      .fs-md-2hx {
        font-size: calc(1.375rem + 1.5vw) !important;
      }
      .fs-md-2tx {
        font-size: calc(1.4rem + 1.8vw) !important;
      }
      .fs-md-3x {
        font-size: calc(1.425rem + 2.1vw) !important;
      }
      .fs-md-3qx {
        font-size: calc(1.45rem + 2.4vw) !important;
      }
      .fs-md-3hx {
        font-size: calc(1.475rem + 2.7vw) !important;
      }
      .fs-md-3tx {
        font-size: calc(1.5rem + 3vw) !important;
      }
      .fs-md-4x {
        font-size: calc(1.525rem + 3.3vw) !important;
      }
      .fs-md-4qx {
        font-size: calc(1.55rem + 3.6vw) !important;
      }
      .fs-md-4hx {
        font-size: calc(1.575rem + 3.9vw) !important;
      }
      .fs-md-4tx {
        font-size: calc(1.6rem + 4.2vw) !important;
      }
      .fs-md-5x {
        font-size: calc(1.625rem + 4.5vw) !important;
      }
      .fs-md-5qx {
        font-size: calc(1.65rem + 4.8vw) !important;
      }
      .fs-md-5hx {
        font-size: calc(1.675rem + 5.1vw) !important;
      }
      .fs-md-5tx {
        font-size: calc(1.7rem + 5.4vw) !important;
      }
}

@media (min-width: 992px) {
    .h-lg-30px {
        height: 30px!important;
    }

    .fs-lg-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
      }
      .fs-lg-2 {
        font-size: calc(1.275rem + 0.3vw) !important;
      }
      .fs-lg-3 {
        font-size: calc(1.26rem + 0.12vw) !important;
      }
      .fs-lg-4 {
        font-size: 1.25rem !important;
      }
      .fs-lg-5 {
        font-size: 1.15rem !important;
      }
      .fs-lg-6 {
        font-size: 1.075rem !important;
      }
      .fs-lg-7 {
        font-size: 0.95rem !important;
      }
      .fs-lg-8 {
        font-size: 0.85rem !important;
      }
      .fs-lg-9 {
        font-size: 0.75rem !important;
      }
      .fs-lg-10 {
        font-size: 0.5rem !important;
      }
      .fs-lg-base {
        font-size: 1rem !important;
      }
      .fs-lg-fluid {
        font-size: 100% !important;
      }
      .fs-lg-2x {
        font-size: calc(1.325rem + 0.9vw) !important;
      }
      .fs-lg-2qx {
        font-size: calc(1.35rem + 1.2vw) !important;
      }
      .fs-lg-2hx {
        font-size: calc(1.375rem + 1.5vw) !important;
      }
      .fs-lg-2tx {
        font-size: calc(1.4rem + 1.8vw) !important;
      }
      .fs-lg-3x {
        font-size: calc(1.425rem + 2.1vw) !important;
      }
      .fs-lg-3qx {
        font-size: calc(1.45rem + 2.4vw) !important;
      }
      .fs-lg-3hx {
        font-size: calc(1.475rem + 2.7vw) !important;
      }
      .fs-lg-3tx {
        font-size: calc(1.5rem + 3vw) !important;
      }
      .fs-lg-4x {
        font-size: calc(1.525rem + 3.3vw) !important;
      }
      .fs-lg-4qx {
        font-size: calc(1.55rem + 3.6vw) !important;
      }
      .fs-lg-4hx {
        font-size: calc(1.575rem + 3.9vw) !important;
      }
      .fs-lg-4tx {
        font-size: calc(1.6rem + 4.2vw) !important;
      }
      .fs-lg-5x {
        font-size: calc(1.625rem + 4.5vw) !important;
      }
      .fs-lg-5qx {
        font-size: calc(1.65rem + 4.8vw) !important;
      }
      .fs-lg-5hx {
        font-size: calc(1.675rem + 5.1vw) !important;
      }
      .fs-lg-5tx {
        font-size: calc(1.7rem + 5.4vw) !important;
      }
}

@media (min-width: 1200px) {
    .fs-xl-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
      }
      .fs-xl-2 {
        font-size: calc(1.275rem + 0.3vw) !important;
      }
      .fs-xl-3 {
        font-size: calc(1.26rem + 0.12vw) !important;
      }
      .fs-xl-4 {
        font-size: 1.25rem !important;
      }
      .fs-xl-5 {
        font-size: 1.15rem !important;
      }
      .fs-xl-6 {
        font-size: 1.075rem !important;
      }
      .fs-xl-7 {
        font-size: 0.95rem !important;
      }
      .fs-xl-8 {
        font-size: 0.85rem !important;
      }
      .fs-xl-9 {
        font-size: 0.75rem !important;
      }
      .fs-xl-10 {
        font-size: 0.5rem !important;
      }
      .fs-xl-base {
        font-size: 1rem !important;
      }
      .fs-xl-fluid {
        font-size: 100% !important;
      }
      .fs-xl-2x {
        font-size: calc(1.325rem + 0.9vw) !important;
      }
      .fs-xl-2qx {
        font-size: calc(1.35rem + 1.2vw) !important;
      }
      .fs-xl-2hx {
        font-size: calc(1.375rem + 1.5vw) !important;
      }
      .fs-xl-2tx {
        font-size: calc(1.4rem + 1.8vw) !important;
      }
      .fs-xl-3x {
        font-size: calc(1.425rem + 2.1vw) !important;
      }
      .fs-xl-3qx {
        font-size: calc(1.45rem + 2.4vw) !important;
      }
      .fs-xl-3hx {
        font-size: calc(1.475rem + 2.7vw) !important;
      }
      .fs-xl-3tx {
        font-size: calc(1.5rem + 3vw) !important;
      }
      .fs-xl-4x {
        font-size: calc(1.525rem + 3.3vw) !important;
      }
      .fs-xl-4qx {
        font-size: calc(1.55rem + 3.6vw) !important;
      }
      .fs-xl-4hx {
        font-size: calc(1.575rem + 3.9vw) !important;
      }
      .fs-xl-4tx {
        font-size: calc(1.6rem + 4.2vw) !important;
      }
      .fs-xl-5x {
        font-size: calc(1.625rem + 4.5vw) !important;
      }
      .fs-xl-5qx {
        font-size: calc(1.65rem + 4.8vw) !important;
      }
      .fs-xl-5hx {
        font-size: calc(1.675rem + 5.1vw) !important;
      }
      .fs-xl-5tx {
        font-size: calc(1.7rem + 5.4vw) !important;
      }
}


@media (min-width: 1200px) {
    .fs-1 {
      font-size: 1.75rem !important;
    }
    .fs-2 {
      font-size: 1.5rem !important;
    }
    .fs-3 {
      font-size: 1.35rem !important;
    }
    .fs-2x {
      font-size: 2rem !important;
    }
    .fs-2qx {
      font-size: 2.25rem !important;
    }
    .fs-2hx {
      font-size: 2.5rem !important;
    }
    .fs-2tx {
      font-size: 2.75rem !important;
    }
    .fs-3x {
      font-size: 3rem !important;
    }
    .fs-3qx {
      font-size: 3.25rem !important;
    }
    .fs-3hx {
      font-size: 3.5rem !important;
    }
    .fs-3tx {
      font-size: 3.75rem !important;
    }
    .fs-4x {
      font-size: 4rem !important;
    }
    .fs-4qx {
      font-size: 4.25rem !important;
    }
    .fs-4hx {
      font-size: 4.5rem !important;
    }
    .fs-4tx {
      font-size: 4.75rem !important;
    }
    .fs-5x {
      font-size: 5rem !important;
    }
    .fs-5qx {
      font-size: 5.25rem !important;
    }
    .fs-5hx {
      font-size: 5.5rem !important;
    }
    .fs-5tx {
      font-size: 5.75rem !important;
    }
    .fs-sm-1 {
      font-size: 1.75rem !important;
    }
    .fs-sm-2 {
      font-size: 1.5rem !important;
    }
    .fs-sm-3 {
      font-size: 1.35rem !important;
    }
    .fs-sm-2x {
      font-size: 2rem !important;
    }
    .fs-sm-2qx {
      font-size: 2.25rem !important;
    }
    .fs-sm-2hx {
      font-size: 2.5rem !important;
    }
    .fs-sm-2tx {
      font-size: 2.75rem !important;
    }
    .fs-sm-3x {
      font-size: 3rem !important;
    }
    .fs-sm-3qx {
      font-size: 3.25rem !important;
    }
    .fs-sm-3hx {
      font-size: 3.5rem !important;
    }
    .fs-sm-3tx {
      font-size: 3.75rem !important;
    }
    .fs-sm-4x {
      font-size: 4rem !important;
    }
    .fs-sm-4qx {
      font-size: 4.25rem !important;
    }
    .fs-sm-4hx {
      font-size: 4.5rem !important;
    }
    .fs-sm-4tx {
      font-size: 4.75rem !important;
    }
    .fs-sm-5x {
      font-size: 5rem !important;
    }
    .fs-sm-5qx {
      font-size: 5.25rem !important;
    }
    .fs-sm-5hx {
      font-size: 5.5rem !important;
    }
    .fs-sm-5tx {
      font-size: 5.75rem !important;
    }
    .fs-md-1 {
      font-size: 1.75rem !important;
    }
    .fs-md-2 {
      font-size: 1.5rem !important;
    }
    .fs-md-3 {
      font-size: 1.35rem !important;
    }
    .fs-md-2x {
      font-size: 2rem !important;
    }
    .fs-md-2qx {
      font-size: 2.25rem !important;
    }
    .fs-md-2hx {
      font-size: 2.5rem !important;
    }
    .fs-md-2tx {
      font-size: 2.75rem !important;
    }
    .fs-md-3x {
      font-size: 3rem !important;
    }
    .fs-md-3qx {
      font-size: 3.25rem !important;
    }
    .fs-md-3hx {
      font-size: 3.5rem !important;
    }
    .fs-md-3tx {
      font-size: 3.75rem !important;
    }
    .fs-md-4x {
      font-size: 4rem !important;
    }
    .fs-md-4qx {
      font-size: 4.25rem !important;
    }
    .fs-md-4hx {
      font-size: 4.5rem !important;
    }
    .fs-md-4tx {
      font-size: 4.75rem !important;
    }
    .fs-md-5x {
      font-size: 5rem !important;
    }
    .fs-md-5qx {
      font-size: 5.25rem !important;
    }
    .fs-md-5hx {
      font-size: 5.5rem !important;
    }
    .fs-md-5tx {
      font-size: 5.75rem !important;
    }
    .fs-lg-1 {
      font-size: 1.75rem !important;
    }
    .fs-lg-2 {
      font-size: 1.5rem !important;
    }
    .fs-lg-3 {
      font-size: 1.35rem !important;
    }
    .fs-lg-2x {
      font-size: 2rem !important;
    }
    .fs-lg-2qx {
      font-size: 2.25rem !important;
    }
    .fs-lg-2hx {
      font-size: 2.5rem !important;
    }
    .fs-lg-2tx {
      font-size: 2.75rem !important;
    }
    .fs-lg-3x {
      font-size: 3rem !important;
    }
    .fs-lg-3qx {
      font-size: 3.25rem !important;
    }
    .fs-lg-3hx {
      font-size: 3.5rem !important;
    }
    .fs-lg-3tx {
      font-size: 3.75rem !important;
    }
    .fs-lg-4x {
      font-size: 4rem !important;
    }
    .fs-lg-4qx {
      font-size: 4.25rem !important;
    }
    .fs-lg-4hx {
      font-size: 4.5rem !important;
    }
    .fs-lg-4tx {
      font-size: 4.75rem !important;
    }
    .fs-lg-5x {
      font-size: 5rem !important;
    }
    .fs-lg-5qx {
      font-size: 5.25rem !important;
    }
    .fs-lg-5hx {
      font-size: 5.5rem !important;
    }
    .fs-lg-5tx {
      font-size: 5.75rem !important;
    }
  }