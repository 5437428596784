body {
    &.theme-dark {
        .form-group {            
            label {
                color: $purple-lighter;
            }
            .form-control {
                input {
                    background-color: $black-medium;
                    border-color: $black-medium;
                    color: $white;
                }
                button {
                    color: $white;
                }
            }
            &.help-icon {
              .field-icon {
                cursor: pointer;
                @extend .btn;
                @extend .btn-light;
                padding: 0;
              }
            }
            .field-icon
            {
              top:0px!important;
            }
        &.textarea {
          .k-label {
            background-color: $black-medium;
          }
        }
        }
        .relaygrid-form {
            .form-group {
                .form-control {
                    background-color: $black-medium;
                    border-color: $black-medium;
                    color: $purple-lighter;
                }
            }
        }
        .k-form {
            .k-form-field {
                color: $white;
            }
            .k-input,
            .k-picker {
                background-color: $black-medium;
                border-color: $black-medium;
                color: $white;
                border-radius: 5px;
            }
            .k-upload {
                border-color: $black-medium;
                .k-dropzone {
                    background-color: $black-medium;
                    color: $purple-lighter;
                }
                .k-upload-button {
                    color: $purple-lighter;
                }
            }
        }
        .k-input-inner {
            color: $white;
            background: #2c2c41;
            border-color: #2c2c41;
        }
        .k-input-solid
        {
          background: none;
          border: none;
        }
        .k-switch-off {
            .k-switch-track {
                background-color: transparent;
                border-color: $primary;
                color: $primary;
                &:hover {
                  background-color: $primary;
                  color: $white;
                }
            }
        }
        .form-check {
            .form-check-input {
                border-color: $purple-lighter;
            }
        }
        .k-form {
            .k-input,
            .k-picker {
                background-color: $black-medium;
                border-color: $black-medium;
                color: $white;
            }
        }
        .k-custom-input {
            .hosted-field-label {
                color: $white;
            }
            .hosted-field {
                background-color: $black-medium;
                border-color: $black-medium;
                color: $purple-lighter;
                &.braintree-hosted-fields-invalid {
                    border-color: $alert;
                }
            }
        }

        .k-floating-label-container {
          &.icon{
            &.k-empty {
              &:not(.k-focus) {
                > .k-label {
                  left: 35px;
                }
              }
            }  
          }
        }
        
        // .k-floating-label-container {
        //   &.textarea {
        //     &.k-state-empty>.k-focus~.k-label,
        //     >.k-label,
        //     &.k-state-focused>.k-label,
        //     &.k-focus>.k-label {
        //         background-color: $black-medium;
        //     }
        //     .k-focus ~ .k-label {
        //       background-color: $black-medium;
        //     }
        //   }


        //   .k-button-icon {
        //     &.k-icon {
        //       &.k-i-arrow-s {
        //         color: $purple-lighter;
        //       }
        //     }
        //   }
        // }

        .custom-select-btn {
          background-color: $dark-btn-bg;
          border-color: $dark-btn-bg;
          color: $purple-lighter;
          padding: 0.7rem 1rem 0.7rem 1.5rem;

          &:hover, &:focus {
            background-color: $dark-btn-bg-hover;
            border-color: $dark-btn-bg-hover;
          }
        }

        .k-menu-popup {
          .k-menu-group {
            .k-item {
              .k-menu-link {
                color: $purple-lighter;
                &:hover, &:focus {
                  background-color: $black-medium;
                  color: $primary;
                }
              }
            }
          }
        }

        .custom-upload {
          .k-upload {
            .k-upload-files {
              li {
                background-color: $black-medium;
                color: $white;
              }
            }
            .k-actions {
              background-color: $black-medium;
              .k-button {
                &.k-clear-selected {
                  @extend .btn-light-danger;
                }
                &.k-upload-selected {
                  @extend .btn-light-primary;
                }
              }
            }
          }
          .form-group {
            
            &:focus {
              border: 0;
              box-shadow: none;
            }
            &.label_height 
              {
                .form-group
                  {
                    background-color: $black-medium;
                  }
              }
          }
          .k-dropzone {
            .k-dropzone-hint {
              color: $white;
            }
            .k-upload-button {
              @extend .btn;
              @extend .btn-light-primary;
              font-size: 0;
              line-height: 0;
              min-height: auto;
              padding: 5px 0 0 0;
            }
          }
        }


        .custom-upload {
          .k-upload {
            .k-upload-files {
              li {
                &:before {
                  color: $purple-30;
                }
              }
            }
          }
        }

        .mp3Player {
          background-color: $black-medium;
          border-color: $black-medium;
          color: $white;
          .field-icon, p {
            color: $white;
          }
        }

        .k-picker-solid {
          background-color: $black-medium;
          border-color: $black-medium;
          color: $white;
        
          &:hover, &:focus {
            background-color: $black-medium;
            border-color: $black-medium;
            color: $white;
          }
        
          .k-input-inner {
            box-shadow: none;
            background-color: transparent;
            border: none;
          }
        }
        
        .k-picker-md .k-input-button {
          padding: 0.375rem;
        }


    }
}
