.portal-main {
  .k-drawer-content {

    // p,
    // li,
    // ul,
    // span,
    // a {
    //   font-size: 12px;
    // }



    .card {
      .card-header {
        .card-title {
          font-size: 14px;

          .card-label {
            font-size: 16px;
          }
        }
      }
    }

    .k-form {

      .k-input,
      .k-picker {
        .k-input-inner {
          font-size: $font-size;
          margin-left: 0px;
        }
      }
    }

    .k-floating-label-container.k-state-empty>.k-focus~.k-label,
    .k-floating-label-container>.k-label,
    .k-floating-label-container.k-state-focused>.k-label,
    .k-floating-label-container.k-focus>.k-label,
    .form-group .form-control input,
    .table td, .table th, .table tr {
      font-size: $font-size;
    }
  }
}
// .theme-mode-main {
//   .theme-button {
//     .light {
//       display: block;
//       transition: 0.3s;
//     }
//     .dark {
//       display: none;
//       transition: 0.3s;
//     }
//   }
//   .menu-link {
//     &.light-theme {
//       color: $primary;
//       pointer-events: none;
//     }
//     &.dark-theme {
//       color: $gray-600;
//       pointer-events: all;
//     }
//   }
// }
