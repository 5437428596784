.k-notification-group {
    .k-notification {
        .k-notification-wrap {
            @include align-items(center);
            
            .k-icon {
                margin-top: 0;
                font-size: 20px;
            }
            .k-notification-content {
                font-size: 14px;
            }
        }
        
        &.k-notification {
            color: $white;
            border-color: #474747;
            background-color: #474747;
            border-radius:0px;
            height: 50px;
            button
            {
                background: transparent;
                color: #fff;
                border-radius: 20px;
                width: 60px;
                font-size: 13px;
                height: 29px;
                border: #6b6b6b solid 1px;
                margin-left: 20px;
            }
            &.k-notification-success {
                color: $white;
                border-color: #007e50;
                background-color: #007e50;
                border-radius:0px;
                height: 50px;
                button
                {
                    border: #319771 solid 1px;
                }
            }
            &.k-notification-info {
                color: $white;
                border-color: #0569e3;
                background-color: #0569e3;
                border-radius:0px;
                height: 50px;
                button
                {
                    border: #217ae6 solid 1px;
                }
            }
            &.k-notification-error {
                color: $white;
                border-color: #ba0203;
                background-color: #ba0203;
                border-radius:0px;
                height: 50px;
                button
                {
                    border: #c93434 solid 1px;
                }
            }
        }
        .k-i-close
        {
            display: none;
        }
        i
        {
            margin-right: 10px;
        }
    }
}
