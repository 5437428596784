.p-0 {
    padding: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .p-3 {
    padding: 0.75rem !important;
  }
  .p-4 {
    padding: 1rem !important;
  }
  .p-5 {
    padding: 1.25rem !important;
  }
  .p-6 {
    padding: 1.5rem !important;
  }
  .p-7 {
    padding: 1.75rem !important;
  }
  .p-8 {
    padding: 2rem !important;
  }
  .p-9 {
    padding: 2.25rem !important;
  }
  .p-10 {
    padding: 2.5rem !important;
  }
  .p-11 {
    padding: 2.75rem !important;
  }
  .p-12 {
    padding: 3rem !important;
  }
  .p-13 {
    padding: 3.25rem !important;
  }
  .p-14 {
    padding: 3.5rem !important;
  }
  .p-15 {
    padding: 3.75rem !important;
  }
  .p-16 {
    padding: 4rem !important;
  }
  .p-17 {
    padding: 4.25rem !important;
  }
  .p-18 {
    padding: 4.5rem !important;
  }
  .p-19 {
    padding: 4.75rem !important;
  }
  .p-20 {
    padding: 5rem !important;
  }
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .px-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }
  .px-20 {
    padding-right: 1rem !important;
    padding-left: 3rem !important;
  }
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }
  .py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .pt-0 {
    padding-top: 0 !important;
  }
  .pt-1 {
    padding-top: 0.25rem !important;
  }
  .pt-2 {
    padding-top: 0.5rem !important;
  }
  .pt-3 {
    padding-top: 0.75rem !important;
  }
  .pt-4 {
    padding-top: 1rem !important;
  }
  .pt-5 {
    padding-top: 1.25rem !important;
  }
  .pt-6 {
    padding-top: 1.5rem !important;
  }
  .pt-7 {
    padding-top: 1.75rem !important;
  }
  .pt-8 {
    padding-top: 2rem !important;
  }
  .pt-9 {
    padding-top: 2.25rem !important;
  }
  .pt-10 {
    padding-top: 2.5rem !important;
  }
  .pt-11 {
    padding-top: 2.75rem !important;
  }
  .pt-12 {
    padding-top: 3rem !important;
  }
  .pt-13 {
    padding-top: 3.25rem !important;
  }
  .pt-14 {
    padding-top: 3.5rem !important;
  }
  .pt-15 {
    padding-top: 3.75rem !important;
  }
  .pt-16 {
    padding-top: 4rem !important;
  }
  .pt-17 {
    padding-top: 4.25rem !important;
  }
  .pt-18 {
    padding-top: 4.5rem !important;
  }
  .pt-19 {
    padding-top: 4.75rem !important;
  }
  .pt-20 {
    padding-top: 5rem !important;
  }
  .pe-0 {
    padding-right: 0 !important;
  }
  .pe-1 {
    padding-right: 0.25rem !important;
  }
  .pe-2 {
    padding-right: 0.5rem !important;
  }
  .pe-3 {
    padding-right: 0.75rem !important;
  }
  .pe-4 {
    padding-right: 1rem !important;
  }
  .pe-5 {
    padding-right: .35rem !important;
  }
  .pe-6 {
    padding-right: 1.5rem !important;
  }
  .pe-7 {
    padding-right: 1.75rem !important;
  }
  .pe-8 {
    padding-right: 2rem !important;
  }
  .pe-9 {
    padding-right: 2.25rem !important;
  }
  .pe-10 {
    padding-right: 2.5rem !important;
  }
  .pe-11 {
    padding-right: 2.75rem !important;
  }
  .pe-12 {
    padding-right: 3rem !important;
  }
  .pe-13 {
    padding-right: 3.25rem !important;
  }
  .pe-14 {
    padding-right: 3.5rem !important;
  }
  .pe-15 {
    padding-right: 3.75rem !important;
  }
  .pe-16 {
    padding-right: 4rem !important;
  }
  .pe-17 {
    padding-right: 4.25rem !important;
  }
  .pe-18 {
    padding-right: 4.5rem !important;
  }
  .pe-19 {
    padding-right: 4.75rem !important;
  }
  .pe-20 {
    padding-right: 5rem !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-4 {
    padding-bottom: 1rem !important;
  }
  .pb-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-8 {
    padding-bottom: 2rem !important;
  }
  .pb-9 {
    padding-bottom: 2.25rem !important;
  }
  .pb-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-11 {
    padding-bottom: 2.75rem !important;
  }
  .pb-12 {
    padding-bottom: 3rem !important;
  }
  .pb-13 {
    padding-bottom: 3.25rem !important;
  }
  .pb-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-15 {
    padding-bottom: 3.75rem !important;
  }
  .pb-16 {
    padding-bottom: 4rem !important;
  }
  .pb-17 {
    padding-bottom: 4.25rem !important;
  }
  .pb-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-19 {
    padding-bottom: 4.75rem !important;
  }
  .pb-20 {
    padding-bottom: 5rem !important;
  }
  .ps-0 {
    padding-left: 0 !important;
  }
  .ps-1 {
    padding-left: 0.25rem !important;
  }
  .ps-2 {
    padding-left: 0.5rem !important;
  }
  .ps-3 {
    padding-left: 0.75rem !important;
  }
  .ps-4 {
    padding-left: 1rem !important;
  }
  .ps-5 {
    padding-left: 1.25rem !important;
  }
  .ps-6 {
    padding-left: 1.5rem !important;
  }
  .ps-7 {
    padding-left: 1.75rem !important;
  }
  .ps-8 {
    padding-left: 2rem !important;
  }
  .ps-9 {
    padding-left: 2.25rem !important;
  }
  .ps-10 {
    padding-left: 2.5rem !important;
  }
  .ps-11 {
    padding-left: 2.75rem !important;
  }
  .ps-12 {
    padding-left: 3rem !important;
  }
  .ps-13 {
    padding-left: 3.25rem !important;
  }
  .ps-14 {
    padding-left: 3.5rem !important;
  }
  .ps-15 {
    padding-left: 3.75rem !important;
  }
  .ps-16 {
    padding-left: 4rem !important;
  }
  .ps-17 {
    padding-left: 4.25rem !important;
  }
  .ps-18 {
    padding-left: 4.5rem !important;
  }
  .ps-19 {
    padding-left: 4.75rem !important;
  }
  .ps-20 {
    padding-left: 5rem !important;
  }

  @media (min-width: 576px) {
    .p-sm-0 {
        padding: 0 !important;
      }
      .p-sm-1 {
        padding: 0.25rem !important;
      }
      .p-sm-2 {
        padding: 0.5rem !important;
      }
      .p-sm-3 {
        padding: 0.75rem !important;
      }
      .p-sm-4 {
        padding: 1rem !important;
      }
      .p-sm-5 {
        padding: 1.25rem !important;
      }
      .p-sm-6 {
        padding: 1.5rem !important;
      }
      .p-sm-7 {
        padding: 1.75rem !important;
      }
      .p-sm-8 {
        padding: 2rem !important;
      }
      .p-sm-9 {
        padding: 2.25rem !important;
      }
      .p-sm-10 {
        padding: 2.5rem !important;
      }
      .p-sm-11 {
        padding: 2.75rem !important;
      }
      .p-sm-12 {
        padding: 3rem !important;
      }
      .p-sm-13 {
        padding: 3.25rem !important;
      }
      .p-sm-14 {
        padding: 3.5rem !important;
      }
      .p-sm-15 {
        padding: 3.75rem !important;
      }
      .p-sm-16 {
        padding: 4rem !important;
      }
      .p-sm-17 {
        padding: 4.25rem !important;
      }
      .p-sm-18 {
        padding: 4.5rem !important;
      }
      .p-sm-19 {
        padding: 4.75rem !important;
      }
      .p-sm-20 {
        padding: 5rem !important;
      }
      .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      .px-sm-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
      }
      .px-sm-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
      }
      .px-sm-3 {
        padding-right: 0.75rem !important;
        padding-left: 0.75rem !important;
      }
      .px-sm-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
      }
      .px-sm-5 {
        padding-right: 1.25rem !important;
        padding-left: 1.25rem !important;
      }
      .px-sm-6 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
      }
      .px-sm-7 {
        padding-right: 1.75rem !important;
        padding-left: 1.75rem !important;
      }
      .px-sm-8 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
      }
      .px-sm-9 {
        padding-right: 2.25rem !important;
        padding-left: 2.25rem !important;
      }
      .px-sm-10 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
      }
      .px-sm-11 {
        padding-right: 2.75rem !important;
        padding-left: 2.75rem !important;
      }
      .px-sm-12 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
      }
      .px-sm-13 {
        padding-right: 3.25rem !important;
        padding-left: 3.25rem !important;
      }
      .px-sm-14 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important;
      }
      .px-sm-15 {
        padding-right: 3.75rem !important;
        padding-left: 3.75rem !important;
      }
      .px-sm-16 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
      }
      .px-sm-17 {
        padding-right: 4.25rem !important;
        padding-left: 4.25rem !important;
      }
      .px-sm-18 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important;
      }
      .px-sm-19 {
        padding-right: 4.75rem !important;
        padding-left: 4.75rem !important;
      }
      .px-sm-20 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
      }
      .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .py-sm-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
      }
      .py-sm-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
      }
      .py-sm-3 {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
      }
      .py-sm-4 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
      }
      .py-sm-5 {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
      }
      .py-sm-6 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
      }
      .py-sm-7 {
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
      }
      .py-sm-8 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
      }
      .py-sm-9 {
        padding-top: 2.25rem !important;
        padding-bottom: 2.25rem !important;
      }
      .py-sm-10 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
      }
      .py-sm-11 {
        padding-top: 2.75rem !important;
        padding-bottom: 2.75rem !important;
      }
      .py-sm-12 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
      }
      .py-sm-13 {
        padding-top: 3.25rem !important;
        padding-bottom: 3.25rem !important;
      }
      .py-sm-14 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
      }
      .py-sm-15 {
        padding-top: 3.75rem !important;
        padding-bottom: 3.75rem !important;
      }
      .py-sm-16 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
      }
      .py-sm-17 {
        padding-top: 4.25rem !important;
        padding-bottom: 4.25rem !important;
      }
      .py-sm-18 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
      }
      .py-sm-19 {
        padding-top: 4.75rem !important;
        padding-bottom: 4.75rem !important;
      }
      .py-sm-20 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
      }
      .pt-sm-0 {
        padding-top: 0 !important;
      }
      .pt-sm-1 {
        padding-top: 0.25rem !important;
      }
      .pt-sm-2 {
        padding-top: 0.5rem !important;
      }
      .pt-sm-3 {
        padding-top: 0.75rem !important;
      }
      .pt-sm-4 {
        padding-top: 1rem !important;
      }
      .pt-sm-5 {
        padding-top: 1.25rem !important;
      }
      .pt-sm-6 {
        padding-top: 1.5rem !important;
      }
      .pt-sm-7 {
        padding-top: 1.75rem !important;
      }
      .pt-sm-8 {
        padding-top: 2rem !important;
      }
      .pt-sm-9 {
        padding-top: 2.25rem !important;
      }
      .pt-sm-10 {
        padding-top: 2.5rem !important;
      }
      .pt-sm-11 {
        padding-top: 2.75rem !important;
      }
      .pt-sm-12 {
        padding-top: 3rem !important;
      }
      .pt-sm-13 {
        padding-top: 3.25rem !important;
      }
      .pt-sm-14 {
        padding-top: 3.5rem !important;
      }
      .pt-sm-15 {
        padding-top: 3.75rem !important;
      }
      .pt-sm-16 {
        padding-top: 4rem !important;
      }
      .pt-sm-17 {
        padding-top: 4.25rem !important;
      }
      .pt-sm-18 {
        padding-top: 4.5rem !important;
      }
      .pt-sm-19 {
        padding-top: 4.75rem !important;
      }
      .pt-sm-20 {
        padding-top: 5rem !important;
      }
      .pe-sm-0 {
        padding-right: 0 !important;
      }
      .pe-sm-1 {
        padding-right: 0.25rem !important;
      }
      .pe-sm-2 {
        padding-right: 0.5rem !important;
      }
      .pe-sm-3 {
        padding-right: 0.75rem !important;
      }
      .pe-sm-4 {
        padding-right: 1rem !important;
      }
      .pe-sm-5 {
        padding-right: 1.25rem !important;
      }
      .pe-sm-6 {
        padding-right: 1.5rem !important;
      }
      .pe-sm-7 {
        padding-right: 1.75rem !important;
      }
      .pe-sm-8 {
        padding-right: 2rem !important;
      }
      .pe-sm-9 {
        padding-right: 2.25rem !important;
      }
      .pe-sm-10 {
        padding-right: 2.5rem !important;
      }
      .pe-sm-11 {
        padding-right: 2.75rem !important;
      }
      .pe-sm-12 {
        padding-right: 3rem !important;
      }
      .pe-sm-13 {
        padding-right: 3.25rem !important;
      }
      .pe-sm-14 {
        padding-right: 3.5rem !important;
      }
      .pe-sm-15 {
        padding-right: 3.75rem !important;
      }
      .pe-sm-16 {
        padding-right: 4rem !important;
      }
      .pe-sm-17 {
        padding-right: 4.25rem !important;
      }
      .pe-sm-18 {
        padding-right: 4.5rem !important;
      }
      .pe-sm-19 {
        padding-right: 4.75rem !important;
      }
      .pe-sm-20 {
        padding-right: 5rem !important;
      }
      .pb-sm-0 {
        padding-bottom: 0 !important;
      }
      .pb-sm-1 {
        padding-bottom: 0.25rem !important;
      }
      .pb-sm-2 {
        padding-bottom: 0.5rem !important;
      }
      .pb-sm-3 {
        padding-bottom: 0.75rem !important;
      }
      .pb-sm-4 {
        padding-bottom: 1rem !important;
      }
      .pb-sm-5 {
        padding-bottom: 1.25rem !important;
      }
      .pb-sm-6 {
        padding-bottom: 1.5rem !important;
      }
      .pb-sm-7 {
        padding-bottom: 1.75rem !important;
      }
      .pb-sm-8 {
        padding-bottom: 2rem !important;
      }
      .pb-sm-9 {
        padding-bottom: 2.25rem !important;
      }
      .pb-sm-10 {
        padding-bottom: 2.5rem !important;
      }
      .pb-sm-11 {
        padding-bottom: 2.75rem !important;
      }
      .pb-sm-12 {
        padding-bottom: 3rem !important;
      }
      .pb-sm-13 {
        padding-bottom: 3.25rem !important;
      }
      .pb-sm-14 {
        padding-bottom: 3.5rem !important;
      }
      .pb-sm-15 {
        padding-bottom: 3.75rem !important;
      }
      .pb-sm-16 {
        padding-bottom: 4rem !important;
      }
      .pb-sm-17 {
        padding-bottom: 4.25rem !important;
      }
      .pb-sm-18 {
        padding-bottom: 4.5rem !important;
      }
      .pb-sm-19 {
        padding-bottom: 4.75rem !important;
      }
      .pb-sm-20 {
        padding-bottom: 5rem !important;
      }
      .ps-sm-0 {
        padding-left: 0 !important;
      }
      .ps-sm-1 {
        padding-left: 0.25rem !important;
      }
      .ps-sm-2 {
        padding-left: 0.5rem !important;
      }
      .ps-sm-3 {
        padding-left: 0.75rem !important;
      }
      .ps-sm-4 {
        padding-left: 1rem !important;
      }
      .ps-sm-5 {
        padding-left: 1.25rem !important;
      }
      .ps-sm-6 {
        padding-left: 1.5rem !important;
      }
      .ps-sm-7 {
        padding-left: 1.75rem !important;
      }
      .ps-sm-8 {
        padding-left: 2rem !important;
      }
      .ps-sm-9 {
        padding-left: 2.25rem !important;
      }
      .ps-sm-10 {
        padding-left: 2.5rem !important;
      }
      .ps-sm-11 {
        padding-left: 2.75rem !important;
      }
      .ps-sm-12 {
        padding-left: 3rem !important;
      }
      .ps-sm-13 {
        padding-left: 3.25rem !important;
      }
      .ps-sm-14 {
        padding-left: 3.5rem !important;
      }
      .ps-sm-15 {
        padding-left: 3.75rem !important;
      }
      .ps-sm-16 {
        padding-left: 4rem !important;
      }
      .ps-sm-17 {
        padding-left: 4.25rem !important;
      }
      .ps-sm-18 {
        padding-left: 4.5rem !important;
      }
      .ps-sm-19 {
        padding-left: 4.75rem !important;
      }
      .ps-sm-20 {
        padding-left: 5rem !important;
      }
  }

  @media (min-width: 768px) {
    .p-md-0 {
        padding: 0 !important;
      }
      .p-md-1 {
        padding: 0.25rem !important;
      }
      .p-md-2 {
        padding: 0.5rem !important;
      }
      .p-md-3 {
        padding: 0.75rem !important;
      }
      .p-md-4 {
        padding: 1rem !important;
      }
      .p-md-5 {
        padding: 1.25rem !important;
      }
      .p-md-6 {
        padding: 1.5rem !important;
      }
      .p-md-7 {
        padding: 1.75rem !important;
      }
      .p-md-8 {
        padding: 2rem !important;
      }
      .p-md-9 {
        padding: 2.25rem !important;
      }
      .p-md-10 {
        padding: 2.5rem !important;
      }
      .p-md-11 {
        padding: 2.75rem !important;
      }
      .p-md-12 {
        padding: 3rem !important;
      }
      .p-md-13 {
        padding: 3.25rem !important;
      }
      .p-md-14 {
        padding: 3.5rem !important;
      }
      .p-md-15 {
        padding: 3.75rem !important;
      }
      .p-md-16 {
        padding: 4rem !important;
      }
      .p-md-17 {
        padding: 4.25rem !important;
      }
      .p-md-18 {
        padding: 4.5rem !important;
      }
      .p-md-19 {
        padding: 4.75rem !important;
      }
      .p-md-20 {
        padding: 5rem !important;
      }
      .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      .px-md-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
      }
      .px-md-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
      }
      .px-md-3 {
        padding-right: 0.75rem !important;
        padding-left: 0.75rem !important;
      }
      .px-md-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
      }
      .px-md-5 {
        padding-right: 1.25rem !important;
        padding-left: 1.25rem !important;
      }
      .px-md-6 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
      }
      .px-md-7 {
        padding-right: 1.75rem !important;
        padding-left: 1.75rem !important;
      }
      .px-md-8 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
      }
      .px-md-9 {
        padding-right: 2.25rem !important;
        padding-left: 2.25rem !important;
      }
      .px-md-10 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
      }
      .px-md-11 {
        padding-right: 2.75rem !important;
        padding-left: 2.75rem !important;
      }
      .px-md-12 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
      }
      .px-md-13 {
        padding-right: 3.25rem !important;
        padding-left: 3.25rem !important;
      }
      .px-md-14 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important;
      }
      .px-md-15 {
        padding-right: 3.75rem !important;
        padding-left: 3.75rem !important;
      }
      .px-md-16 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
      }
      .px-md-17 {
        padding-right: 4.25rem !important;
        padding-left: 4.25rem !important;
      }
      .px-md-18 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important;
      }
      .px-md-19 {
        padding-right: 4.75rem !important;
        padding-left: 4.75rem !important;
      }
      .px-md-20 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
      }
      .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .py-md-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
      }
      .py-md-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
      }
      .py-md-3 {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
      }
      .py-md-4 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
      }
      .py-md-5 {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
      }
      .py-md-6 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
      }
      .py-md-7 {
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
      }
      .py-md-8 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
      }
      .py-md-9 {
        padding-top: 2.25rem !important;
        padding-bottom: 2.25rem !important;
      }
      .py-md-10 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
      }
      .py-md-11 {
        padding-top: 2.75rem !important;
        padding-bottom: 2.75rem !important;
      }
      .py-md-12 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
      }
      .py-md-13 {
        padding-top: 3.25rem !important;
        padding-bottom: 3.25rem !important;
      }
      .py-md-14 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
      }
      .py-md-15 {
        padding-top: 3.75rem !important;
        padding-bottom: 3.75rem !important;
      }
      .py-md-16 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
      }
      .py-md-17 {
        padding-top: 4.25rem !important;
        padding-bottom: 4.25rem !important;
      }
      .py-md-18 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
      }
      .py-md-19 {
        padding-top: 4.75rem !important;
        padding-bottom: 4.75rem !important;
      }
      .py-md-20 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
      }
      .pt-md-0 {
        padding-top: 0 !important;
      }
      .pt-md-1 {
        padding-top: 0.25rem !important;
      }
      .pt-md-2 {
        padding-top: 0.5rem !important;
      }
      .pt-md-3 {
        padding-top: 0.75rem !important;
      }
      .pt-md-4 {
        padding-top: 1rem !important;
      }
      .pt-md-5 {
        padding-top: 1.25rem !important;
      }
      .pt-md-6 {
        padding-top: 1.5rem !important;
      }
      .pt-md-7 {
        padding-top: 1.75rem !important;
      }
      .pt-md-8 {
        padding-top: 2rem !important;
      }
      .pt-md-9 {
        padding-top: 2.25rem !important;
      }
      .pt-md-10 {
        padding-top: 2.5rem !important;
      }
      .pt-md-11 {
        padding-top: 2.75rem !important;
      }
      .pt-md-12 {
        padding-top: 3rem !important;
      }
      .pt-md-13 {
        padding-top: 3.25rem !important;
      }
      .pt-md-14 {
        padding-top: 3.5rem !important;
      }
      .pt-md-15 {
        padding-top: 3.75rem !important;
      }
      .pt-md-16 {
        padding-top: 4rem !important;
      }
      .pt-md-17 {
        padding-top: 4.25rem !important;
      }
      .pt-md-18 {
        padding-top: 4.5rem !important;
      }
      .pt-md-19 {
        padding-top: 4.75rem !important;
      }
      .pt-md-20 {
        padding-top: 5rem !important;
      }
      .pe-md-0 {
        padding-right: 0 !important;
      }
      .pe-md-1 {
        padding-right: 0.25rem !important;
      }
      .pe-md-2 {
        padding-right: 0.5rem !important;
      }
      .pe-md-3 {
        padding-right: 0.75rem !important;
      }
      .pe-md-4 {
        padding-right: 1rem !important;
      }
      .pe-md-5 {
        padding-right: 1.25rem !important;
      }
      .pe-md-6 {
        padding-right: 1.5rem !important;
      }
      .pe-md-7 {
        padding-right: 1.75rem !important;
      }
      .pe-md-8 {
        padding-right: 2rem !important;
      }
      .pe-md-9 {
        padding-right: 2.25rem !important;
      }
      .pe-md-10 {
        padding-right: 2.5rem !important;
      }
      .pe-md-11 {
        padding-right: 2.75rem !important;
      }
      .pe-md-12 {
        padding-right: 3rem !important;
      }
      .pe-md-13 {
        padding-right: 3.25rem !important;
      }
      .pe-md-14 {
        padding-right: 3.5rem !important;
      }
      .pe-md-15 {
        padding-right: 3.75rem !important;
      }
      .pe-md-16 {
        padding-right: 4rem !important;
      }
      .pe-md-17 {
        padding-right: 4.25rem !important;
      }
      .pe-md-18 {
        padding-right: 4.5rem !important;
      }
      .pe-md-19 {
        padding-right: 4.75rem !important;
      }
      .pe-md-20 {
        padding-right: 5rem !important;
      }
      .pb-md-0 {
        padding-bottom: 0 !important;
      }
      .pb-md-1 {
        padding-bottom: 0.25rem !important;
      }
      .pb-md-2 {
        padding-bottom: 0.5rem !important;
      }
      .pb-md-3 {
        padding-bottom: 0.75rem !important;
      }
      .pb-md-4 {
        padding-bottom: 1rem !important;
      }
      .pb-md-5 {
        padding-bottom: 1.25rem !important;
      }
      .pb-md-6 {
        padding-bottom: 1.5rem !important;
      }
      .pb-md-7 {
        padding-bottom: 1.75rem !important;
      }
      .pb-md-8 {
        padding-bottom: 2rem !important;
      }
      .pb-md-9 {
        padding-bottom: 2.25rem !important;
      }
      .pb-md-10 {
        padding-bottom: 2.5rem !important;
      }
      .pb-md-11 {
        padding-bottom: 2.75rem !important;
      }
      .pb-md-12 {
        padding-bottom: 3rem !important;
      }
      .pb-md-13 {
        padding-bottom: 3.25rem !important;
      }
      .pb-md-14 {
        padding-bottom: 3.5rem !important;
      }
      .pb-md-15 {
        padding-bottom: 3.75rem !important;
      }
      .pb-md-16 {
        padding-bottom: 4rem !important;
      }
      .pb-md-17 {
        padding-bottom: 4.25rem !important;
      }
      .pb-md-18 {
        padding-bottom: 4.5rem !important;
      }
      .pb-md-19 {
        padding-bottom: 4.75rem !important;
      }
      .pb-md-20 {
        padding-bottom: 5rem !important;
      }
      .ps-md-0 {
        padding-left: 0 !important;
      }
      .ps-md-1 {
        padding-left: 0.25rem !important;
      }
      .ps-md-2 {
        padding-left: 0.5rem !important;
      }
      .ps-md-3 {
        padding-left: 0.75rem !important;
      }
      .ps-md-4 {
        padding-left: 1rem !important;
      }
      .ps-md-5 {
        padding-left: 1.25rem !important;
      }
      .ps-md-6 {
        padding-left: 1.5rem !important;
      }
      .ps-md-7 {
        padding-left: 1.75rem !important;
      }
      .ps-md-8 {
        padding-left: 2rem !important;
      }
      .ps-md-9 {
        padding-left: 2.25rem !important;
      }
      .ps-md-10 {
        padding-left: 2.5rem !important;
      }
      .ps-md-11 {
        padding-left: 2.75rem !important;
      }
      .ps-md-12 {
        padding-left: 3rem !important;
      }
      .ps-md-13 {
        padding-left: 3.25rem !important;
      }
      .ps-md-14 {
        padding-left: 3.5rem !important;
      }
      .ps-md-15 {
        padding-left: 3.75rem !important;
      }
      .ps-md-16 {
        padding-left: 4rem !important;
      }
      .ps-md-17 {
        padding-left: 4.25rem !important;
      }
      .ps-md-18 {
        padding-left: 4.5rem !important;
      }
      .ps-md-19 {
        padding-left: 4.75rem !important;
      }
      .ps-md-20 {
        padding-left: 5rem !important;
      }
  }

  @media (min-width: 992px) {
    .p-lg-0 {
        padding: 0 !important;
      }
      .p-lg-1 {
        padding: 0.25rem !important;
      }
      .p-lg-2 {
        padding: 0.5rem !important;
      }
      .p-lg-3 {
        padding: 0.75rem !important;
      }
      .p-lg-4 {
        padding: 1rem !important;
      }
      .p-lg-5 {
        padding: 1.25rem !important;
      }
      .p-lg-6 {
        padding: 1.5rem !important;
      }
      .p-lg-7 {
        padding: 1.75rem !important;
      }
      .p-lg-8 {
        padding: 2rem !important;
      }
      .p-lg-9 {
        padding: 2.25rem !important;
      }
      .p-lg-10 {
        padding: 2.5rem !important;
      }
      .p-lg-11 {
        padding: 2.75rem !important;
      }
      .p-lg-12 {
        padding: 3rem !important;
      }
      .p-lg-13 {
        padding: 3.25rem !important;
      }
      .p-lg-14 {
        padding: 3.5rem !important;
      }
      .p-lg-15 {
        padding: 3.75rem !important;
      }
      .p-lg-16 {
        padding: 4rem !important;
      }
      .p-lg-17 {
        padding: 4.25rem !important;
      }
      .p-lg-18 {
        padding: 4.5rem !important;
      }
      .p-lg-19 {
        padding: 4.75rem !important;
      }
      .p-lg-20 {
        padding: 5rem !important;
      }
      .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      .px-lg-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
      }
      .px-lg-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
      }
      .px-lg-3 {
        padding-right: 0.75rem !important;
        padding-left: 0.75rem !important;
      }
      .px-lg-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
      }
      .px-lg-5 {
        padding-right: 1.25rem !important;
        padding-left: 1.25rem !important;
      }
      .px-lg-6 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
      }
      .px-lg-7 {
        padding-right: 1.75rem !important;
        padding-left: 1.75rem !important;
      }
      .px-lg-8 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
      }
      .px-lg-9 {
        padding-right: 2.25rem !important;
        padding-left: 2.25rem !important;
      }
      .px-lg-10 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
      }
      .px-lg-11 {
        padding-right: 2.75rem !important;
        padding-left: 2.75rem !important;
      }
      .px-lg-12 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
      }
      .px-lg-13 {
        padding-right: 3.25rem !important;
        padding-left: 3.25rem !important;
      }
      .px-lg-14 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important;
      }
      .px-lg-15 {
        padding-right: 3.75rem !important;
        padding-left: 3.75rem !important;
      }
      .px-lg-16 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
      }
      .px-lg-17 {
        padding-right: 4.25rem !important;
        padding-left: 4.25rem !important;
      }
      .px-lg-18 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important;
      }
      .px-lg-19 {
        padding-right: 4.75rem !important;
        padding-left: 4.75rem !important;
      }
      .px-lg-20 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
      }
      .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .py-lg-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
      }
      .py-lg-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
      }
      .py-lg-3 {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
      }
      .py-lg-4 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
      }
      .py-lg-5 {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
      }
      .py-lg-6 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
      }
      .py-lg-7 {
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
      }
      .py-lg-8 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
      }
      .py-lg-9 {
        padding-top: 2.25rem !important;
        padding-bottom: 2.25rem !important;
      }
      .py-lg-10 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
      }
      .py-lg-11 {
        padding-top: 2.75rem !important;
        padding-bottom: 2.75rem !important;
      }
      .py-lg-12 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
      }
      .py-lg-13 {
        padding-top: 3.25rem !important;
        padding-bottom: 3.25rem !important;
      }
      .py-lg-14 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
      }
      .py-lg-15 {
        padding-top: 3.75rem !important;
        padding-bottom: 3.75rem !important;
      }
      .py-lg-16 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
      }
      .py-lg-17 {
        padding-top: 4.25rem !important;
        padding-bottom: 4.25rem !important;
      }
      .py-lg-18 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
      }
      .py-lg-19 {
        padding-top: 4.75rem !important;
        padding-bottom: 4.75rem !important;
      }
      .py-lg-20 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
      }
      .pt-lg-0 {
        padding-top: 0 !important;
      }
      .pt-lg-1 {
        padding-top: 0.25rem !important;
      }
      .pt-lg-2 {
        padding-top: 0.5rem !important;
      }
      .pt-lg-3 {
        padding-top: 0.75rem !important;
      }
      .pt-lg-4 {
        padding-top: 1rem !important;
      }
      .pt-lg-5 {
        padding-top: 1.25rem !important;
      }
      .pt-lg-6 {
        padding-top: 1.5rem !important;
      }
      .pt-lg-7 {
        padding-top: 1.75rem !important;
      }
      .pt-lg-8 {
        padding-top: 2rem !important;
      }
      .pt-lg-9 {
        padding-top: 2.25rem !important;
      }
      .pt-lg-10 {
        padding-top: 2.5rem !important;
      }
      .pt-lg-11 {
        padding-top: 2.75rem !important;
      }
      .pt-lg-12 {
        padding-top: 3rem !important;
      }
      .pt-lg-13 {
        padding-top: 3.25rem !important;
      }
      .pt-lg-14 {
        padding-top: 3.5rem !important;
      }
      .pt-lg-15 {
        padding-top: 3.75rem !important;
      }
      .pt-lg-16 {
        padding-top: 4rem !important;
      }
      .pt-lg-17 {
        padding-top: 4.25rem !important;
      }
      .pt-lg-18 {
        padding-top: 4.5rem !important;
      }
      .pt-lg-19 {
        padding-top: 4.75rem !important;
      }
      .pt-lg-20 {
        padding-top: 5rem !important;
      }
      .pe-lg-0 {
        padding-right: 0 !important;
      }
      .pe-lg-1 {
        padding-right: 0.25rem !important;
      }
      .pe-lg-2 {
        padding-right: 0.5rem !important;
      }
      .pe-lg-3 {
        padding-right: 0.75rem !important;
      }
      .pe-lg-4 {
        padding-right: 1rem !important;
      }
      .pe-lg-5 {
        padding-right: 1.25rem !important;
      }
      .pe-lg-6 {
        padding-right: 1.5rem !important;
      }
      .pe-lg-7 {
        padding-right: 1.75rem !important;
      }
      .pe-lg-8 {
        padding-right: 2rem !important;
      }
      .pe-lg-9 {
        padding-right: 2.25rem !important;
      }
      .pe-lg-10 {
        padding-right: 2.5rem !important;
      }
      .pe-lg-11 {
        padding-right: 2.75rem !important;
      }
      .pe-lg-12 {
        padding-right: 3rem !important;
      }
      .pe-lg-13 {
        padding-right: 3.25rem !important;
      }
      .pe-lg-14 {
        padding-right: 3.5rem !important;
      }
      .pe-lg-15 {
        padding-right: 3.75rem !important;
      }
      .pe-lg-16 {
        padding-right: 4rem !important;
      }
      .pe-lg-17 {
        padding-right: 4.25rem !important;
      }
      .pe-lg-18 {
        padding-right: 4.5rem !important;
      }
      .pe-lg-19 {
        padding-right: 4.75rem !important;
      }
      .pe-lg-20 {
        padding-right: 5rem !important;
      }
      .pb-lg-0 {
        padding-bottom: 0 !important;
      }
      .pb-lg-1 {
        padding-bottom: 0.25rem !important;
      }
      .pb-lg-2 {
        padding-bottom: 0.5rem !important;
      }
      .pb-lg-3 {
        padding-bottom: 0.75rem !important;
      }
      .pb-lg-4 {
        padding-bottom: 1rem !important;
      }
      .pb-lg-5 {
        padding-bottom: 1.25rem !important;
      }
      .pb-lg-6 {
        padding-bottom: 1.5rem !important;
      }
      .pb-lg-7 {
        padding-bottom: 1.75rem !important;
      }
      .pb-lg-8 {
        padding-bottom: 2rem !important;
      }
      .pb-lg-9 {
        padding-bottom: 2.25rem !important;
      }
      .pb-lg-10 {
        padding-bottom: 2.5rem !important;
      }
      .pb-lg-11 {
        padding-bottom: 2.75rem !important;
      }
      .pb-lg-12 {
        padding-bottom: 3rem !important;
      }
      .pb-lg-13 {
        padding-bottom: 3.25rem !important;
      }
      .pb-lg-14 {
        padding-bottom: 3.5rem !important;
      }
      .pb-lg-15 {
        padding-bottom: 3.75rem !important;
      }
      .pb-lg-16 {
        padding-bottom: 4rem !important;
      }
      .pb-lg-17 {
        padding-bottom: 4.25rem !important;
      }
      .pb-lg-18 {
        padding-bottom: 4.5rem !important;
      }
      .pb-lg-19 {
        padding-bottom: 4.75rem !important;
      }
      .pb-lg-20 {
        padding-bottom: 5rem !important;
      }
      .ps-lg-0 {
        padding-left: 0 !important;
      }
      .ps-lg-1 {
        padding-left: 0.25rem !important;
      }
      .ps-lg-2 {
        padding-left: 0.5rem !important;
      }
      .ps-lg-3 {
        padding-left: 0.75rem !important;
      }
      .ps-lg-4 {
        padding-left: 1rem !important;
      }
      .ps-lg-5 {
        padding-left: 1.25rem !important;
      }
      .ps-lg-6 {
        padding-left: 1.5rem !important;
      }
      .ps-lg-7 {
        padding-left: 1.75rem !important;
      }
      .ps-lg-8 {
        padding-left: 2rem !important;
      }
      .ps-lg-9 {
        padding-left: 2.25rem !important;
      }
      .ps-lg-10 {
        padding-left: 2.5rem !important;
      }
      .ps-lg-11 {
        padding-left: 2.75rem !important;
      }
      .ps-lg-12 {
        padding-left: 3rem !important;
      }
      .ps-lg-13 {
        padding-left: 3.25rem !important;
      }
      .ps-lg-14 {
        padding-left: 3.5rem !important;
      }
      .ps-lg-15 {
        padding-left: 3.75rem !important;
      }
      .ps-lg-16 {
        padding-left: 4rem !important;
      }
      .ps-lg-17 {
        padding-left: 4.25rem !important;
      }
      .ps-lg-18 {
        padding-left: 4.5rem !important;
      }
      .ps-lg-19 {
        padding-left: 4.75rem !important;
      }
      .ps-lg-20 {
        padding-left: 5rem !important;
      }
  }

  @media (min-width: 1200px) {
    .p-xl-0 {
        padding: 0 !important;
      }
      .p-xl-1 {
        padding: 0.25rem !important;
      }
      .p-xl-2 {
        padding: 0.5rem !important;
      }
      .p-xl-3 {
        padding: 0.75rem !important;
      }
      .p-xl-4 {
        padding: 1rem !important;
      }
      .p-xl-5 {
        padding: 1.25rem !important;
      }
      .p-xl-6 {
        padding: 1.5rem !important;
      }
      .p-xl-7 {
        padding: 1.75rem !important;
      }
      .p-xl-8 {
        padding: 2rem !important;
      }
      .p-xl-9 {
        padding: 2.25rem !important;
      }
      .p-xl-10 {
        padding: 2.5rem !important;
      }
      .p-xl-11 {
        padding: 2.75rem !important;
      }
      .p-xl-12 {
        padding: 3rem !important;
      }
      .p-xl-13 {
        padding: 3.25rem !important;
      }
      .p-xl-14 {
        padding: 3.5rem !important;
      }
      .p-xl-15 {
        padding: 3.75rem !important;
      }
      .p-xl-16 {
        padding: 4rem !important;
      }
      .p-xl-17 {
        padding: 4.25rem !important;
      }
      .p-xl-18 {
        padding: 4.5rem !important;
      }
      .p-xl-19 {
        padding: 4.75rem !important;
      }
      .p-xl-20 {
        padding: 5rem !important;
      }
      .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      .px-xl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
      }
      .px-xl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
      }
      .px-xl-3 {
        padding-right: 0.75rem !important;
        padding-left: 0.75rem !important;
      }
      .px-xl-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
      }
      .px-xl-5 {
        padding-right: 1.25rem !important;
        padding-left: 1.25rem !important;
      }
      .px-xl-6 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
      }
      .px-xl-7 {
        padding-right: 1.75rem !important;
        padding-left: 1.75rem !important;
      }
      .px-xl-8 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
      }
      .px-xl-9 {
        padding-right: 2.25rem !important;
        padding-left: 2.25rem !important;
      }
      .px-xl-10 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
      }
      .px-xl-11 {
        padding-right: 2.75rem !important;
        padding-left: 2.75rem !important;
      }
      .px-xl-12 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
      }
      .px-xl-13 {
        padding-right: 3.25rem !important;
        padding-left: 3.25rem !important;
      }
      .px-xl-14 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important;
      }
      .px-xl-15 {
        padding-right: 3.75rem !important;
        padding-left: 3.75rem !important;
      }
      .px-xl-16 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
      }
      .px-xl-17 {
        padding-right: 4.25rem !important;
        padding-left: 4.25rem !important;
      }
      .px-xl-18 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important;
      }
      .px-xl-19 {
        padding-right: 4.75rem !important;
        padding-left: 4.75rem !important;
      }
      .px-xl-20 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
      }
      .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .py-xl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
      }
      .py-xl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
      }
      .py-xl-3 {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
      }
      .py-xl-4 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
      }
      .py-xl-5 {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
      }
      .py-xl-6 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
      }
      .py-xl-7 {
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
      }
      .py-xl-8 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
      }
      .py-xl-9 {
        padding-top: 2.25rem !important;
        padding-bottom: 2.25rem !important;
      }
      .py-xl-10 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
      }
      .py-xl-11 {
        padding-top: 2.75rem !important;
        padding-bottom: 2.75rem !important;
      }
      .py-xl-12 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
      }
      .py-xl-13 {
        padding-top: 3.25rem !important;
        padding-bottom: 3.25rem !important;
      }
      .py-xl-14 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
      }
      .py-xl-15 {
        padding-top: 3.75rem !important;
        padding-bottom: 3.75rem !important;
      }
      .py-xl-16 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
      }
      .py-xl-17 {
        padding-top: 4.25rem !important;
        padding-bottom: 4.25rem !important;
      }
      .py-xl-18 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
      }
      .py-xl-19 {
        padding-top: 4.75rem !important;
        padding-bottom: 4.75rem !important;
      }
      .py-xl-20 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
      }
      .pt-xl-0 {
        padding-top: 0 !important;
      }
      .pt-xl-1 {
        padding-top: 0.25rem !important;
      }
      .pt-xl-2 {
        padding-top: 0.5rem !important;
      }
      .pt-xl-3 {
        padding-top: 0.75rem !important;
      }
      .pt-xl-4 {
        padding-top: 1rem !important;
      }
      .pt-xl-5 {
        padding-top: 1.25rem !important;
      }
      .pt-xl-6 {
        padding-top: 1.5rem !important;
      }
      .pt-xl-7 {
        padding-top: 1.75rem !important;
      }
      .pt-xl-8 {
        padding-top: 2rem !important;
      }
      .pt-xl-9 {
        padding-top: 2.25rem !important;
      }
      .pt-xl-10 {
        padding-top: 2.5rem !important;
      }
      .pt-xl-11 {
        padding-top: 2.75rem !important;
      }
      .pt-xl-12 {
        padding-top: 3rem !important;
      }
      .pt-xl-13 {
        padding-top: 3.25rem !important;
      }
      .pt-xl-14 {
        padding-top: 3.5rem !important;
      }
      .pt-xl-15 {
        padding-top: 3.75rem !important;
      }
      .pt-xl-16 {
        padding-top: 4rem !important;
      }
      .pt-xl-17 {
        padding-top: 4.25rem !important;
      }
      .pt-xl-18 {
        padding-top: 4.5rem !important;
      }
      .pt-xl-19 {
        padding-top: 4.75rem !important;
      }
      .pt-xl-20 {
        padding-top: 5rem !important;
      }
      .pe-xl-0 {
        padding-right: 0 !important;
      }
      .pe-xl-1 {
        padding-right: 0.25rem !important;
      }
      .pe-xl-2 {
        padding-right: 0.5rem !important;
      }
      .pe-xl-3 {
        padding-right: 0.75rem !important;
      }
      .pe-xl-4 {
        padding-right: 1rem !important;
      }
      .pe-xl-5 {
        padding-right: 1.25rem !important;
      }
      .pe-xl-6 {
        padding-right: 1.5rem !important;
      }
      .pe-xl-7 {
        padding-right: 1.75rem !important;
      }
      .pe-xl-8 {
        padding-right: 2rem !important;
      }
      .pe-xl-9 {
        padding-right: 2.25rem !important;
      }
      .pe-xl-10 {
        padding-right: 2.5rem !important;
      }
      .pe-xl-11 {
        padding-right: 2.75rem !important;
      }
      .pe-xl-12 {
        padding-right: 3rem !important;
      }
      .pe-xl-13 {
        padding-right: 3.25rem !important;
      }
      .pe-xl-14 {
        padding-right: 3.5rem !important;
      }
      .pe-xl-15 {
        padding-right: 3.75rem !important;
      }
      .pe-xl-16 {
        padding-right: 4rem !important;
      }
      .pe-xl-17 {
        padding-right: 4.25rem !important;
      }
      .pe-xl-18 {
        padding-right: 4.5rem !important;
      }
      .pe-xl-19 {
        padding-right: 4.75rem !important;
      }
      .pe-xl-20 {
        padding-right: 5rem !important;
      }
      .pb-xl-0 {
        padding-bottom: 0 !important;
      }
      .pb-xl-1 {
        padding-bottom: 0.25rem !important;
      }
      .pb-xl-2 {
        padding-bottom: 0.5rem !important;
      }
      .pb-xl-3 {
        padding-bottom: 0.75rem !important;
      }
      .pb-xl-4 {
        padding-bottom: 1rem !important;
      }
      .pb-xl-5 {
        padding-bottom: 1.25rem !important;
      }
      .pb-xl-6 {
        padding-bottom: 1.5rem !important;
      }
      .pb-xl-7 {
        padding-bottom: 1.75rem !important;
      }
      .pb-xl-8 {
        padding-bottom: 2rem !important;
      }
      .pb-xl-9 {
        padding-bottom: 2.25rem !important;
      }
      .pb-xl-10 {
        padding-bottom: 2.5rem !important;
      }
      .pb-xl-11 {
        padding-bottom: 2.75rem !important;
      }
      .pb-xl-12 {
        padding-bottom: 3rem !important;
      }
      .pb-xl-13 {
        padding-bottom: 3.25rem !important;
      }
      .pb-xl-14 {
        padding-bottom: 3.5rem !important;
      }
      .pb-xl-15 {
        padding-bottom: 3.75rem !important;
      }
      .pb-xl-16 {
        padding-bottom: 4rem !important;
      }
      .pb-xl-17 {
        padding-bottom: 4.25rem !important;
      }
      .pb-xl-18 {
        padding-bottom: 4.5rem !important;
      }
      .pb-xl-19 {
        padding-bottom: 4.75rem !important;
      }
      .pb-xl-20 {
        padding-bottom: 5rem !important;
      }
      .ps-xl-0 {
        padding-left: 0 !important;
      }
      .ps-xl-1 {
        padding-left: 0.25rem !important;
      }
      .ps-xl-2 {
        padding-left: 0.5rem !important;
      }
      .ps-xl-3 {
        padding-left: 0.75rem !important;
      }
      .ps-xl-4 {
        padding-left: 1rem !important;
      }
      .ps-xl-5 {
        padding-left: 1.25rem !important;
      }
      .ps-xl-6 {
        padding-left: 1.5rem !important;
      }
      .ps-xl-7 {
        padding-left: 1.75rem !important;
      }
      .ps-xl-8 {
        padding-left: 2rem !important;
      }
      .ps-xl-9 {
        padding-left: 2.25rem !important;
      }
      .ps-xl-10 {
        padding-left: 2.5rem !important;
      }
      .ps-xl-11 {
        padding-left: 2.75rem !important;
      }
      .ps-xl-12 {
        padding-left: 3rem !important;
      }
      .ps-xl-13 {
        padding-left: 3.25rem !important;
      }
      .ps-xl-14 {
        padding-left: 3.5rem !important;
      }
      .ps-xl-15 {
        padding-left: 3.75rem !important;
      }
      .ps-xl-16 {
        padding-left: 4rem !important;
      }
      .ps-xl-17 {
        padding-left: 4.25rem !important;
      }
      .ps-xl-18 {
        padding-left: 4.5rem !important;
      }
      .ps-xl-19 {
        padding-left: 4.75rem !important;
      }
      .ps-xl-20 {
        padding-left: 5rem !important;
      }
  }

  @media (min-width: 1400px) {
    .p-xxl-0 {
        padding: 0 !important;
      }
      .p-xxl-1 {
        padding: 0.25rem !important;
      }
      .p-xxl-2 {
        padding: 0.5rem !important;
      }
      .p-xxl-3 {
        padding: 0.75rem !important;
      }
      .p-xxl-4 {
        padding: 1rem !important;
      }
      .p-xxl-5 {
        padding: 1.25rem !important;
      }
      .p-xxl-6 {
        padding: 1.5rem !important;
      }
      .p-xxl-7 {
        padding: 1.75rem !important;
      }
      .p-xxl-8 {
        padding: 2rem !important;
      }
      .p-xxl-9 {
        padding: 2.25rem !important;
      }
      .p-xxl-10 {
        padding: 2.5rem !important;
      }
      .p-xxl-11 {
        padding: 2.75rem !important;
      }
      .p-xxl-12 {
        padding: 3rem !important;
      }
      .p-xxl-13 {
        padding: 3.25rem !important;
      }
      .p-xxl-14 {
        padding: 3.5rem !important;
      }
      .p-xxl-15 {
        padding: 3.75rem !important;
      }
      .p-xxl-16 {
        padding: 4rem !important;
      }
      .p-xxl-17 {
        padding: 4.25rem !important;
      }
      .p-xxl-18 {
        padding: 4.5rem !important;
      }
      .p-xxl-19 {
        padding: 4.75rem !important;
      }
      .p-xxl-20 {
        padding: 5rem !important;
      }
      .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      .px-xxl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
      }
      .px-xxl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
      }
      .px-xxl-3 {
        padding-right: 0.75rem !important;
        padding-left: 0.75rem !important;
      }
      .px-xxl-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
      }
      .px-xxl-5 {
        padding-right: 1.25rem !important;
        padding-left: 1.25rem !important;
      }
      .px-xxl-6 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
      }
      .px-xxl-7 {
        padding-right: 1.75rem !important;
        padding-left: 1.75rem !important;
      }
      .px-xxl-8 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
      }
      .px-xxl-9 {
        padding-right: 2.25rem !important;
        padding-left: 2.25rem !important;
      }
      .px-xxl-10 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
      }
      .px-xxl-11 {
        padding-right: 2.75rem !important;
        padding-left: 2.75rem !important;
      }
      .px-xxl-12 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
      }
      .px-xxl-13 {
        padding-right: 3.25rem !important;
        padding-left: 3.25rem !important;
      }
      .px-xxl-14 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important;
      }
      .px-xxl-15 {
        padding-right: 3.75rem !important;
        padding-left: 3.75rem !important;
      }
      .px-xxl-16 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
      }
      .px-xxl-17 {
        padding-right: 4.25rem !important;
        padding-left: 4.25rem !important;
      }
      .px-xxl-18 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important;
      }
      .px-xxl-19 {
        padding-right: 4.75rem !important;
        padding-left: 4.75rem !important;
      }
      .px-xxl-20 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
      }
      .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .py-xxl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
      }
      .py-xxl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
      }
      .py-xxl-3 {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
      }
      .py-xxl-4 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
      }
      .py-xxl-5 {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
      }
      .py-xxl-6 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
      }
      .py-xxl-7 {
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
      }
      .py-xxl-8 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
      }
      .py-xxl-9 {
        padding-top: 2.25rem !important;
        padding-bottom: 2.25rem !important;
      }
      .py-xxl-10 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
      }
      .py-xxl-11 {
        padding-top: 2.75rem !important;
        padding-bottom: 2.75rem !important;
      }
      .py-xxl-12 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
      }
      .py-xxl-13 {
        padding-top: 3.25rem !important;
        padding-bottom: 3.25rem !important;
      }
      .py-xxl-14 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
      }
      .py-xxl-15 {
        padding-top: 3.75rem !important;
        padding-bottom: 3.75rem !important;
      }
      .py-xxl-16 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
      }
      .py-xxl-17 {
        padding-top: 4.25rem !important;
        padding-bottom: 4.25rem !important;
      }
      .py-xxl-18 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
      }
      .py-xxl-19 {
        padding-top: 4.75rem !important;
        padding-bottom: 4.75rem !important;
      }
      .py-xxl-20 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
      }
      .pt-xxl-0 {
        padding-top: 0 !important;
      }
      .pt-xxl-1 {
        padding-top: 0.25rem !important;
      }
      .pt-xxl-2 {
        padding-top: 0.5rem !important;
      }
      .pt-xxl-3 {
        padding-top: 0.75rem !important;
      }
      .pt-xxl-4 {
        padding-top: 1rem !important;
      }
      .pt-xxl-5 {
        padding-top: 1.25rem !important;
      }
      .pt-xxl-6 {
        padding-top: 1.5rem !important;
      }
      .pt-xxl-7 {
        padding-top: 1.75rem !important;
      }
      .pt-xxl-8 {
        padding-top: 2rem !important;
      }
      .pt-xxl-9 {
        padding-top: 2.25rem !important;
      }
      .pt-xxl-10 {
        padding-top: 2.5rem !important;
      }
      .pt-xxl-11 {
        padding-top: 2.75rem !important;
      }
      .pt-xxl-12 {
        padding-top: 3rem !important;
      }
      .pt-xxl-13 {
        padding-top: 3.25rem !important;
      }
      .pt-xxl-14 {
        padding-top: 3.5rem !important;
      }
      .pt-xxl-15 {
        padding-top: 3.75rem !important;
      }
      .pt-xxl-16 {
        padding-top: 4rem !important;
      }
      .pt-xxl-17 {
        padding-top: 4.25rem !important;
      }
      .pt-xxl-18 {
        padding-top: 4.5rem !important;
      }
      .pt-xxl-19 {
        padding-top: 4.75rem !important;
      }
      .pt-xxl-20 {
        padding-top: 5rem !important;
      }
      .pe-xxl-0 {
        padding-right: 0 !important;
      }
      .pe-xxl-1 {
        padding-right: 0.25rem !important;
      }
      .pe-xxl-2 {
        padding-right: 0.5rem !important;
      }
      .pe-xxl-3 {
        padding-right: 0.75rem !important;
      }
      .pe-xxl-4 {
        padding-right: 1rem !important;
      }
      .pe-xxl-5 {
        padding-right: 1.25rem !important;
      }
      .pe-xxl-6 {
        padding-right: 1.5rem !important;
      }
      .pe-xxl-7 {
        padding-right: 1.75rem !important;
      }
      .pe-xxl-8 {
        padding-right: 2rem !important;
      }
      .pe-xxl-9 {
        padding-right: 2.25rem !important;
      }
      .pe-xxl-10 {
        padding-right: 2.5rem !important;
      }
      .pe-xxl-11 {
        padding-right: 2.75rem !important;
      }
      .pe-xxl-12 {
        padding-right: 3rem !important;
      }
      .pe-xxl-13 {
        padding-right: 3.25rem !important;
      }
      .pe-xxl-14 {
        padding-right: 3.5rem !important;
      }
      .pe-xxl-15 {
        padding-right: 3.75rem !important;
      }
      .pe-xxl-16 {
        padding-right: 4rem !important;
      }
      .pe-xxl-17 {
        padding-right: 4.25rem !important;
      }
      .pe-xxl-18 {
        padding-right: 4.5rem !important;
      }
      .pe-xxl-19 {
        padding-right: 4.75rem !important;
      }
      .pe-xxl-20 {
        padding-right: 5rem !important;
      }
      .pb-xxl-0 {
        padding-bottom: 0 !important;
      }
      .pb-xxl-1 {
        padding-bottom: 0.25rem !important;
      }
      .pb-xxl-2 {
        padding-bottom: 0.5rem !important;
      }
      .pb-xxl-3 {
        padding-bottom: 0.75rem !important;
      }
      .pb-xxl-4 {
        padding-bottom: 1rem !important;
      }
      .pb-xxl-5 {
        padding-bottom: 1.25rem !important;
      }
      .pb-xxl-6 {
        padding-bottom: 1.5rem !important;
      }
      .pb-xxl-7 {
        padding-bottom: 1.75rem !important;
      }
      .pb-xxl-8 {
        padding-bottom: 2rem !important;
      }
      .pb-xxl-9 {
        padding-bottom: 2.25rem !important;
      }
      .pb-xxl-10 {
        padding-bottom: 2.5rem !important;
      }
      .pb-xxl-11 {
        padding-bottom: 2.75rem !important;
      }
      .pb-xxl-12 {
        padding-bottom: 3rem !important;
      }
      .pb-xxl-13 {
        padding-bottom: 3.25rem !important;
      }
      .pb-xxl-14 {
        padding-bottom: 3.5rem !important;
      }
      .pb-xxl-15 {
        padding-bottom: 3.75rem !important;
      }
      .pb-xxl-16 {
        padding-bottom: 4rem !important;
      }
      .pb-xxl-17 {
        padding-bottom: 4.25rem !important;
      }
      .pb-xxl-18 {
        padding-bottom: 4.5rem !important;
      }
      .pb-xxl-19 {
        padding-bottom: 4.75rem !important;
      }
      .pb-xxl-20 {
        padding-bottom: 5rem !important;
      }
      .ps-xxl-0 {
        padding-left: 0 !important;
      }
      .ps-xxl-1 {
        padding-left: 0.25rem !important;
      }
      .ps-xxl-2 {
        padding-left: 0.5rem !important;
      }
      .ps-xxl-3 {
        padding-left: 0.75rem !important;
      }
      .ps-xxl-4 {
        padding-left: 1rem !important;
      }
      .ps-xxl-5 {
        padding-left: 1.25rem !important;
      }
      .ps-xxl-6 {
        padding-left: 1.5rem !important;
      }
      .ps-xxl-7 {
        padding-left: 1.75rem !important;
      }
      .ps-xxl-8 {
        padding-left: 2rem !important;
      }
      .ps-xxl-9 {
        padding-left: 2.25rem !important;
      }
      .ps-xxl-10 {
        padding-left: 2.5rem !important;
      }
      .ps-xxl-11 {
        padding-left: 2.75rem !important;
      }
      .ps-xxl-12 {
        padding-left: 3rem !important;
      }
      .ps-xxl-13 {
        padding-left: 3.25rem !important;
      }
      .ps-xxl-14 {
        padding-left: 3.5rem !important;
      }
      .ps-xxl-15 {
        padding-left: 3.75rem !important;
      }
      .ps-xxl-16 {
        padding-left: 4rem !important;
      }
      .ps-xxl-17 {
        padding-left: 4.25rem !important;
      }
      .ps-xxl-18 {
        padding-left: 4.5rem !important;
      }
      .ps-xxl-19 {
        padding-left: 4.75rem !important;
      }
      .ps-xxl-20 {
        padding-left: 5rem !important;
      }
  }