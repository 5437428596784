$primary: #0d6efd;
// $primary: #009ef7;
$primary-hover: #0095e8;
$primary-light: #f1faff;
$primary-dark: #212E48;

$secondary: #6c757d;
$secondary-hover: #b5b5c3;
$secondary-medium: #8f8e8e;
$secondary-light: #edf7ff;
$secondary-dark: #1b1b29;



$tertiary: #007F84;

$blue-faded: #3e5176;
$neon-pink: #FF6EC7;

$info: #7239EA;
// $info: #8850fb;
$info-dark: #5014d0;
$info-hover: #5014d0;
$info-medium: #6049b5;
$info-light: #f8f5ff;
$info-dark1: #2f264f;

$warning: #FFC700;
$warning-hover: #f1bc00;
$warning-light: #fff8dd;
$warning-dark: #392f28;

$success: #50CD89;
$success-hover: #47be7d;
$success-2: #ceffe4;
$success-light: #e8fff3;
$success-dark: #27a861;
$success-dark1: #1c3238;

$danger: #F1416C;
$danger-hover: #d9214e;
$danger-light: #fff5f8;
$danger-dark: #3a2434;

$error: $danger;
$error-hover: $danger-hover;
$error-light: $danger-light;
$error-dark: $danger-dark;
$alert: #dc3545;

$purple-lighter: #92929F;
$purple-20: #878aa0;
$purple-30: #565674;
$purple-40: #323248;
$purple-50: #2B2B40;
$purple-medium: #1b1b29;
$purple-dark: #1e1e2d;
$purple-darker: #151521;
$purple-bold: #0e0e16;

$white: #ffffff;
$white-2: rgba(255, 255, 255, 0.2);
$white-6: rgba(255, 255, 255, 0.6);

$black: #000000;
$black-1: #212529;
$r-black: #181C32;
$black-80: rgba(0, 0, 0, 0.8);
$black-60: rgba(0, 0, 0, 0.6);
$black-40: rgba(0, 0, 0, 0.4);
$black-medium: #2c2c41;

$gray-lighter: #F5F8FA;
$gray: #A1A5B7;
$gray-400: #EFF2F5;
$gray-500: #CDCDDE;
$gray-600: #7E8299;
$gray-medium: #5E6278;
$gray-dark: #2d2d43;
$light-color: $gray-lighter;
$light-color-hover: #e4e6ef;
$light-theme-border: #dadada;

$dark: #181c32;
$dark-hover: #131628;
$dark-light: #eff2f5;



$dark-theme-text-color: #9595a7;

$dark-btn-bg: #2a2b3a;
$dark-btn-bg-hover: #474761;



//Font family
$font-primary: Hellix,Helvetica,sans-serif;
$font-size: 14px;

// Font weight
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

//Border
$theme-border: $light-color;