.user-avtar {
    border: 0;
    background-color: transparent;
    .k-dropzone {
        padding: 0;
        background-color: transparent;
        .k-button {
            margin-top: 0.5rem;
            @extend .btn-light-primary;
        }
    }
    .k-dropzone-hint {
        display: none;
    }
}
