body {
  &.theme-dark {
    .k-popup {
      background-color: $purple-dark;
      border-color: $purple-darker;
      box-shadow: 0 2px 4px 0 rgb(255 255 255 / 3%), 0 4px 5px 0 rgb(255 255 255 / 4%);

      .k-input,
      .k-picker {
        background-color: $purple-medium;
        border-color: $purple-medium;
      }

      ul {
        li {
          background-color: $purple-40;
          color: $white;
          border: 0;
          box-shadow: none;

          &:hover {
            background-color: $purple-40;
            color: $primary;
            span {
              background-color: transparent;
            }
          }

          &[class*="select"] {
            background-color: $primary;
            color: $white;
            border: 0;
            outline: 0;
            box-shadow: none;
          }
        }
      }
    }

    .k-popover {
      background-color: $purple-dark;
      border-color: $gray-dark;
      color: $purple-lighter;

      .k-popover-callout {
        border-color: $gray-dark;
        background-color: $purple-darker;
      }

      .k-popover-header {
        border-color: $gray-dark;
        background-color: $purple-darker;
      }

      .img-block {
        border-color: $gray-dark;
      }

      .popover-content {
        color: $white;
      }

      .k-popover-actions {
        border-color: $gray-dark;

        .btn-custom {
          &.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {

            &:hover,
            &:focus {
              background-color: $primary-dark;
            }
          }
        }
      }
    }
  }
}
