body {
    &.theme-dark {
        .mView {
            .k-splitter {
                background-color: transparent;
                color: $white;
            }
            .k-content {
                .k-pane {
                    .pane-content {
                        .k-chat {
                            .k-message-box {
                                border-color: $purple-40 !important;
                            }
                        }
                    }
                }
            }
        }
        .k-panelbar {
            &.tooglable-keypad {
                .k-item {
                    .k-link {
                        .k-panelbar-item-icon,
                        .k-icon {
                            color: $purple-lighter;
                        }
                    }
                }
            }
        }
        .k-splitbar {
            color: $purple-lighter;
            background-color: $purple-dark;
        }
        .k-panelbar {
            color: $purple-30;
            border-color: $gray-dark;
        }
        .dialerpad .noItem {
            color: $purple-30;
            &:hover,
            &:focus {
                background-color: $gray-dark;
                color: $white;
            }
        }
        .k-chat {
            .k-bubble {
                background-color: $gray-dark;
                border-color: $gray-dark;
                color: $white;
            }
        }
        .details-layout {
            .k-tabstrip-top {
                >.k-tabstrip-items-wrapper {
                    .k-item {
                        border-color: $gray-dark;
                        border-right-color: transparent;
                        &.k-state-active {
                            border-right-color: $primary;
                            border-bottom-color: $gray-dark;
                        }
                    }
                }
            }
            .k-tabstrip-items-wrapper {
                border-color: $gray-dark;
                background-color: $purple-dark;
            }
            .pane-top {
                kendo-card-header {
                    border-color: $purple-40;
                }
            }
        }
        .paneitemlist {
            .panelistitem {
                border-bottom-color: $purple-40;
            }
        }
    }
}
