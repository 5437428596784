body {
  &.theme-dark {

    .menu-option-table {

      thead,
      tbody {
        tr {
          border-color: $purple-lighter;
        }
      }

      &.calls-detail-table {
        kendo-sortable {
          >div {

            &:hover {
              tr {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    .menu-option-table {
      &.calls-detail-table {
        .logo[title=queue] {
          .type-img {
            &.call {
              &.light {
                display: none;
              }

              &.dark {
                margin-top: -6px;
                display: block;
              }
            }
          }
        }
        .digit {
          &:before, &:after {
            color: $info-dark1;
          }
          &:before {
            top: -37px;
          }
          &:after {
            bottom: -37px;
          }
        }
      }
    }

    .digit {
      .symbol {
        .drag-icon {
          fill: $info-medium;
        }
      }
    }

    .menuTypeRow {
      &[title="queue"] {
        .nav-icon {
          position: relative;

          img {
            opacity: 0;
          }

          &:after {
            content: '';
            background: url('/assets/media/svg/types/queue-dark-icon.svg');
            width: 35px;
            height: 35px;
            background-size: 35px;
            left: 0;
            top: -6px;
            position: absolute;
          }
        }
      }
    }
    .menu-option-table {
      .mo-table-head {
        background-color: $purple-bold;
        color: $white;
      }
    }

    .menusOptionForm {
      border: 1px solid $info-medium;
      background-color: $purple-darker;

      &.row-view-only {
        border-style: dashed;
        border-color: transparent transparent $info-dark1;
        background-color: transparent;
      }
    }

    .menuItemSelector {
      background-color: transparent;
    }


  }
}
