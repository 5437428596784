.k-popup {
    .k-input,
    .k-picker {
        background-color: $gray-lighter;
        border-color: $gray-lighter;
        .k-input-inner,
        .k-inner-picker {
            background-color: transparent;
            border: 0;
            box-shadow: none;
        }
    }

    ul {
      li {
        border: 0;
        box-shadow: none;

        &:hover {
          color: $primary;
          span {
            background-color: transparent;
          }
        }

        &[class*="select"] {
          background-color: $primary;
          color: $white;
          border: 0;
          outline: 0;
          box-shadow: none;
        }
      }
    }
}

.k-popover {
  .k-popover-callout {
    border-color: $light-theme-border;
  }
  .k-popover-header {
    background-color: transparent;
    border-color: $light-theme-border;
  }
  .img-block {
    @include flex-combinator(row, wrap, center, center);
    img {
      max-height: 212px;
    }
  }

  .k-popover-actions {
    border-top: 1px solid $light-theme-border;
    padding: 0.5rem 1rem;
    margin: 0;

    .btn-custom {
      &.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
        background-color: transparent;
        color: $primary;
        border: 0;
        padding: 6px;
        min-height: auto;

        &:hover, &:focus {
          background-color: $primary-light;
          color: $primary;
        }
      }
    }
  }
}

.custom-popup {
  &.k-popover {
    width: 296px;
    .k-popup {
      border: 0;
    }
  }
}


//kendo-dialog

kendo-dialog {
  .k-dialog {
    border: 0;
  }
  kendo-dialog-titlebar {
    background-color: $purple-dark;
    color: $white;
  }
  .k-window-title, .k-dialog-title, .k-dialog {
    font-size: $font-size;
  }
  .confirmation-icon {
    font-size: 40px;
  }
  .k-actions {
    @include flex-combinator(row, nowrap, center, flex-end);
    padding-left: 1rem;
    padding-right: 1rem; 
    button {
      flex: 0 1 auto;
    }
  }
}