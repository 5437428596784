.header {
    .header-brand {
        padding: 0 20px;
    }
    .header-right {
        flex: 1;
    }

    .custom-select-btn {
      height: 45px;
    }
    .header-brand {
        background-color: $purple-dark;
        padding: 0 20px;
        @media (min-width: 992px) {
            height: 74px;
            width: 265px;
            border-bottom: 0;
            flex-shrink: 0;
        }
    }
}

// .header-right {
//     .k-button {
//         position: fixed;
//         right: 50px;
//         top: 10px;
//         z-index: 999;
//     }
// }
