.w-unset {
    width: unset !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .w-1px {
    width: 1px !important;
  }
  .w-2px {
    width: 2px !important;
  }
  .w-3px {
    width: 3px !important;
  }
  .w-4px {
    width: 4px !important;
  }
  .w-5px {
    width: 5px !important;
  }
  .w-6px {
    width: 6px !important;
  }
  .w-7px {
    width: 7px !important;
  }
  .w-8px {
    width: 8px !important;
  }
  .w-9px {
    width: 9px !important;
  }
  .w-10px {
    width: 10px !important;
  }
  .w-15px {
    width: 15px !important;
  }
  .w-20px {
    width: 20px !important;
  }
  .w-25px {
    width: 25px !important;
  }
  .w-30px {
    width: 30px !important;
  }
  .w-35px {
    width: 35px !important;
  }
  .w-40px {
    width: 40px !important;
  }
  .w-45px {
    width: 45px !important;
  }
  .w-50px {
    width: 50px !important;
  }
  .w-55px {
    width: 55px !important;
  }
  .w-60px {
    width: 60px !important;
  }
  .w-65px {
    width: 65px !important;
  }
  .w-70px {
    width: 70px !important;
  }
  .w-75px {
    width: 75px !important;
  }
  .w-80px {
    width: 80px !important;
  }
  .w-85px {
    width: 85px !important;
  }
  .w-90px {
    width: 90px !important;
  }
  .w-95px {
    width: 95px !important;
  }
  .w-100px {
    width: 100px !important;
  }
  .w-125px {
    width: 125px !important;
  }
  .w-150px {
    width: 150px !important;
  }
  .w-175px {
    width: 175px !important;
  }
  .w-200px {
    width: 200px !important;
  }
  .w-225px {
    width: 225px !important;
  }
  .w-250px {
    width: 250px !important;
  }
  .w-275px {
    width: 275px !important;
  }
  .w-300px {
    width: 300px !important;
  }
  .w-325px {
    width: 325px !important;
  }
  .w-350px {
    width: 350px !important;
  }
  .w-375px {
    width: 375px !important;
  }
  .w-400px {
    width: 400px !important;
  }
  .w-425px {
    width: 425px !important;
  }
  .w-450px {
    width: 450px !important;
  }
  .w-475px {
    width: 475px !important;
  }
  .w-500px {
    width: 500px !important;
  }
  .w-550px {
    width: 550px !important;
  }
  .w-600px {
    width: 600px !important;
  }
  .w-650px {
    width: 650px !important;
  }
  .w-700px {
    width: 700px !important;
  }
  .w-750px {
    width: 750px !important;
  }
  .w-800px {
    width: 800px !important;
  }
  .w-850px {
    width: 850px !important;
  }
  .w-900px {
    width: 900px !important;
  }
  .w-950px {
    width: 950px !important;
  }
  .w-1000px {
    width: 1000px !important;
  }

  
  @media (min-width: 576px) {
    .w-sm-unset {
        width: unset !important;
      }
      .w-sm-25 {
        width: 25% !important;
      }
      .w-sm-50 {
        width: 50% !important;
      }
      .w-sm-75 {
        width: 75% !important;
      }
      .w-sm-100 {
        width: 100% !important;
      }
      .w-sm-auto {
        width: auto !important;
      }
      .w-sm-1px {
        width: 1px !important;
      }
      .w-sm-2px {
        width: 2px !important;
      }
      .w-sm-3px {
        width: 3px !important;
      }
      .w-sm-4px {
        width: 4px !important;
      }
      .w-sm-5px {
        width: 5px !important;
      }
      .w-sm-6px {
        width: 6px !important;
      }
      .w-sm-7px {
        width: 7px !important;
      }
      .w-sm-8px {
        width: 8px !important;
      }
      .w-sm-9px {
        width: 9px !important;
      }
      .w-sm-10px {
        width: 10px !important;
      }
      .w-sm-15px {
        width: 15px !important;
      }
      .w-sm-20px {
        width: 20px !important;
      }
      .w-sm-25px {
        width: 25px !important;
      }
      .w-sm-30px {
        width: 30px !important;
      }
      .w-sm-35px {
        width: 35px !important;
      }
      .w-sm-40px {
        width: 40px !important;
      }
      .w-sm-45px {
        width: 45px !important;
      }
      .w-sm-50px {
        width: 50px !important;
      }
      .w-sm-55px {
        width: 55px !important;
      }
      .w-sm-60px {
        width: 60px !important;
      }
      .w-sm-65px {
        width: 65px !important;
      }
      .w-sm-70px {
        width: 70px !important;
      }
      .w-sm-75px {
        width: 75px !important;
      }
      .w-sm-80px {
        width: 80px !important;
      }
      .w-sm-85px {
        width: 85px !important;
      }
      .w-sm-90px {
        width: 90px !important;
      }
      .w-sm-95px {
        width: 95px !important;
      }
      .w-sm-100px {
        width: 100px !important;
      }
      .w-sm-125px {
        width: 125px !important;
      }
      .w-sm-150px {
        width: 150px !important;
      }
      .w-sm-175px {
        width: 175px !important;
      }
      .w-sm-200px {
        width: 200px !important;
      }
      .w-sm-225px {
        width: 225px !important;
      }
      .w-sm-250px {
        width: 250px !important;
      }
      .w-sm-275px {
        width: 275px !important;
      }
      .w-sm-300px {
        width: 300px !important;
      }
      .w-sm-325px {
        width: 325px !important;
      }
      .w-sm-350px {
        width: 350px !important;
      }
      .w-sm-375px {
        width: 375px !important;
      }
      .w-sm-400px {
        width: 400px !important;
      }
      .w-sm-425px {
        width: 425px !important;
      }
      .w-sm-450px {
        width: 450px !important;
      }
      .w-sm-475px {
        width: 475px !important;
      }
      .w-sm-500px {
        width: 500px !important;
      }
      .w-sm-550px {
        width: 550px !important;
      }
      .w-sm-600px {
        width: 600px !important;
      }
      .w-sm-650px {
        width: 650px !important;
      }
      .w-sm-700px {
        width: 700px !important;
      }
      .w-sm-750px {
        width: 750px !important;
      }
      .w-sm-800px {
        width: 800px !important;
      }
      .w-sm-850px {
        width: 850px !important;
      }
      .w-sm-900px {
        width: 900px !important;
      }
      .w-sm-950px {
        width: 950px !important;
      }
      .w-sm-1000px {
        width: 1000px !important;
      }
  }

  @media (min-width: 768px) { 
    .w-md-unset {
        width: unset !important;
      }
      .w-md-25 {
        width: 25% !important;
      }
      .w-md-50 {
        width: 50% !important;
      }
      .w-md-75 {
        width: 75% !important;
      }
      .w-md-100 {
        width: 100% !important;
      }
      .w-md-auto {
        width: auto !important;
      }
      .w-md-1px {
        width: 1px !important;
      }
      .w-md-2px {
        width: 2px !important;
      }
      .w-md-3px {
        width: 3px !important;
      }
      .w-md-4px {
        width: 4px !important;
      }
      .w-md-5px {
        width: 5px !important;
      }
      .w-md-6px {
        width: 6px !important;
      }
      .w-md-7px {
        width: 7px !important;
      }
      .w-md-8px {
        width: 8px !important;
      }
      .w-md-9px {
        width: 9px !important;
      }
      .w-md-10px {
        width: 10px !important;
      }
      .w-md-15px {
        width: 15px !important;
      }
      .w-md-20px {
        width: 20px !important;
      }
      .w-md-25px {
        width: 25px !important;
      }
      .w-md-30px {
        width: 30px !important;
      }
      .w-md-35px {
        width: 35px !important;
      }
      .w-md-40px {
        width: 40px !important;
      }
      .w-md-45px {
        width: 45px !important;
      }
      .w-md-50px {
        width: 50px !important;
      }
      .w-md-55px {
        width: 55px !important;
      }
      .w-md-60px {
        width: 60px !important;
      }
      .w-md-65px {
        width: 65px !important;
      }
      .w-md-70px {
        width: 70px !important;
      }
      .w-md-75px {
        width: 75px !important;
      }
      .w-md-80px {
        width: 80px !important;
      }
      .w-md-85px {
        width: 85px !important;
      }
      .w-md-90px {
        width: 90px !important;
      }
      .w-md-95px {
        width: 95px !important;
      }
      .w-md-100px {
        width: 100px !important;
      }
      .w-md-125px {
        width: 125px !important;
      }
      .w-md-150px {
        width: 150px !important;
      }
      .w-md-175px {
        width: 175px !important;
      }
      .w-md-200px {
        width: 200px !important;
      }
      .w-md-225px {
        width: 225px !important;
      }
      .w-md-250px {
        width: 250px !important;
      }
      .w-md-275px {
        width: 275px !important;
      }
      .w-md-300px {
        width: 300px !important;
      }
      .w-md-325px {
        width: 325px !important;
      }
      .w-md-350px {
        width: 350px !important;
      }
      .w-md-375px {
        width: 375px !important;
      }
      .w-md-400px {
        width: 400px !important;
      }
      .w-md-425px {
        width: 425px !important;
      }
      .w-md-450px {
        width: 450px !important;
      }
      .w-md-475px {
        width: 475px !important;
      }
      .w-md-500px {
        width: 500px !important;
      }
      .w-md-550px {
        width: 550px !important;
      }
      .w-md-600px {
        width: 600px !important;
      }
      .w-md-650px {
        width: 650px !important;
      }
      .w-md-700px {
        width: 700px !important;
      }
      .w-md-750px {
        width: 750px !important;
      }
      .w-md-800px {
        width: 800px !important;
      }
      .w-md-850px {
        width: 850px !important;
      }
      .w-md-900px {
        width: 900px !important;
      }
      .w-md-950px {
        width: 950px !important;
      }
      .w-md-1000px {
        width: 1000px !important;
      }
  }

  @media (min-width: 992px) {
    .w-lg-unset {
        width: unset !important;
      }
      .w-lg-25 {
        width: 25% !important;
      }
      .w-lg-50 {
        width: 50% !important;
      }
      .w-lg-75 {
        width: 75% !important;
      }
      .w-lg-100 {
        width: 100% !important;
      }
      .w-lg-auto {
        width: auto !important;
      }
      .w-lg-1px {
        width: 1px !important;
      }
      .w-lg-2px {
        width: 2px !important;
      }
      .w-lg-3px {
        width: 3px !important;
      }
      .w-lg-4px {
        width: 4px !important;
      }
      .w-lg-5px {
        width: 5px !important;
      }
      .w-lg-6px {
        width: 6px !important;
      }
      .w-lg-7px {
        width: 7px !important;
      }
      .w-lg-8px {
        width: 8px !important;
      }
      .w-lg-9px {
        width: 9px !important;
      }
      .w-lg-10px {
        width: 10px !important;
      }
      .w-lg-15px {
        width: 15px !important;
      }
      .w-lg-20px {
        width: 20px !important;
      }
      .w-lg-25px {
        width: 25px !important;
      }
      .w-lg-30px {
        width: 30px !important;
      }
      .w-lg-35px {
        width: 35px !important;
      }
      .w-lg-40px {
        width: 40px !important;
      }
      .w-lg-45px {
        width: 45px !important;
      }
      .w-lg-50px {
        width: 50px !important;
      }
      .w-lg-55px {
        width: 55px !important;
      }
      .w-lg-60px {
        width: 60px !important;
      }
      .w-lg-65px {
        width: 65px !important;
      }
      .w-lg-70px {
        width: 70px !important;
      }
      .w-lg-75px {
        width: 75px !important;
      }
      .w-lg-80px {
        width: 80px !important;
      }
      .w-lg-85px {
        width: 85px !important;
      }
      .w-lg-90px {
        width: 90px !important;
      }
      .w-lg-95px {
        width: 95px !important;
      }
      .w-lg-100px {
        width: 100px !important;
      }
      .w-lg-125px {
        width: 125px !important;
      }
      .w-lg-150px {
        width: 150px !important;
      }
      .w-lg-175px {
        width: 175px !important;
      }
      .w-lg-200px {
        width: 200px !important;
      }
      .w-lg-225px {
        width: 225px !important;
      }
      .w-lg-250px {
        width: 250px !important;
      }
      .w-lg-275px {
        width: 275px !important;
      }
      .w-lg-300px {
        width: 300px !important;
      }
      .w-lg-325px {
        width: 325px !important;
      }
      .w-lg-350px {
        width: 350px !important;
      }
      .w-lg-375px {
        width: 375px !important;
      }
      .w-lg-400px {
        width: 400px !important;
      }
      .w-lg-425px {
        width: 425px !important;
      }
      .w-lg-450px {
        width: 450px !important;
      }
      .w-lg-475px {
        width: 475px !important;
      }
      .w-lg-500px {
        width: 500px !important;
      }
      .w-lg-550px {
        width: 550px !important;
      }
      .w-lg-600px {
        width: 600px !important;
      }
      .w-lg-650px {
        width: 650px !important;
      }
      .w-lg-700px {
        width: 700px !important;
      }
      .w-lg-750px {
        width: 750px !important;
      }
      .w-lg-800px {
        width: 800px !important;
      }
      .w-lg-850px {
        width: 850px !important;
      }
      .w-lg-900px {
        width: 900px !important;
      }
      .w-lg-950px {
        width: 950px !important;
      }
      .w-lg-1000px {
        width: 1000px !important;
      }
  }

  @media (min-width: 1200px) {
    .w-xl-unset {
        width: unset !important;
      }
      .w-xl-25 {
        width: 25% !important;
      }
      .w-xl-50 {
        width: 50% !important;
      }
      .w-xl-75 {
        width: 75% !important;
      }
      .w-xl-100 {
        width: 100% !important;
      }
      .w-xl-auto {
        width: auto !important;
      }
      .w-xl-1px {
        width: 1px !important;
      }
      .w-xl-2px {
        width: 2px !important;
      }
      .w-xl-3px {
        width: 3px !important;
      }
      .w-xl-4px {
        width: 4px !important;
      }
      .w-xl-5px {
        width: 5px !important;
      }
      .w-xl-6px {
        width: 6px !important;
      }
      .w-xl-7px {
        width: 7px !important;
      }
      .w-xl-8px {
        width: 8px !important;
      }
      .w-xl-9px {
        width: 9px !important;
      }
      .w-xl-10px {
        width: 10px !important;
      }
      .w-xl-15px {
        width: 15px !important;
      }
      .w-xl-20px {
        width: 20px !important;
      }
      .w-xl-25px {
        width: 25px !important;
      }
      .w-xl-30px {
        width: 30px !important;
      }
      .w-xl-35px {
        width: 35px !important;
      }
      .w-xl-40px {
        width: 40px !important;
      }
      .w-xl-45px {
        width: 45px !important;
      }
      .w-xl-50px {
        width: 50px !important;
      }
      .w-xl-55px {
        width: 55px !important;
      }
      .w-xl-60px {
        width: 60px !important;
      }
      .w-xl-65px {
        width: 65px !important;
      }
      .w-xl-70px {
        width: 70px !important;
      }
      .w-xl-75px {
        width: 75px !important;
      }
      .w-xl-80px {
        width: 80px !important;
      }
      .w-xl-85px {
        width: 85px !important;
      }
      .w-xl-90px {
        width: 90px !important;
      }
      .w-xl-95px {
        width: 95px !important;
      }
      .w-xl-100px {
        width: 100px !important;
      }
      .w-xl-125px {
        width: 125px !important;
      }
      .w-xl-150px {
        width: 150px !important;
      }
      .w-xl-175px {
        width: 175px !important;
      }
      .w-xl-200px {
        width: 200px !important;
      }
      .w-xl-225px {
        width: 225px !important;
      }
      .w-xl-250px {
        width: 250px !important;
      }
      .w-xl-275px {
        width: 275px !important;
      }
      .w-xl-300px {
        width: 300px !important;
      }
      .w-xl-325px {
        width: 325px !important;
      }
      .w-xl-350px {
        width: 350px !important;
      }
      .w-xl-375px {
        width: 375px !important;
      }
      .w-xl-400px {
        width: 400px !important;
      }
      .w-xl-425px {
        width: 425px !important;
      }
      .w-xl-450px {
        width: 450px !important;
      }
      .w-xl-475px {
        width: 475px !important;
      }
      .w-xl-500px {
        width: 500px !important;
      }
      .w-xl-550px {
        width: 550px !important;
      }
      .w-xl-600px {
        width: 600px !important;
      }
      .w-xl-650px {
        width: 650px !important;
      }
      .w-xl-700px {
        width: 700px !important;
      }
      .w-xl-750px {
        width: 750px !important;
      }
      .w-xl-800px {
        width: 800px !important;
      }
      .w-xl-850px {
        width: 850px !important;
      }
      .w-xl-900px {
        width: 900px !important;
      }
      .w-xl-950px {
        width: 950px !important;
      }
      .w-xl-1000px {
        width: 1000px !important;
      }
  }

  @media (min-width: 1400px) {
    .w-xxl-unset {
        width: unset !important;
      }
      .w-xxl-25 {
        width: 25% !important;
      }
      .w-xxl-50 {
        width: 50% !important;
      }
      .w-xxl-75 {
        width: 75% !important;
      }
      .w-xxl-100 {
        width: 100% !important;
      }
      .w-xxl-auto {
        width: auto !important;
      }
      .w-xxl-1px {
        width: 1px !important;
      }
      .w-xxl-2px {
        width: 2px !important;
      }
      .w-xxl-3px {
        width: 3px !important;
      }
      .w-xxl-4px {
        width: 4px !important;
      }
      .w-xxl-5px {
        width: 5px !important;
      }
      .w-xxl-6px {
        width: 6px !important;
      }
      .w-xxl-7px {
        width: 7px !important;
      }
      .w-xxl-8px {
        width: 8px !important;
      }
      .w-xxl-9px {
        width: 9px !important;
      }
      .w-xxl-10px {
        width: 10px !important;
      }
      .w-xxl-15px {
        width: 15px !important;
      }
      .w-xxl-20px {
        width: 20px !important;
      }
      .w-xxl-25px {
        width: 25px !important;
      }
      .w-xxl-30px {
        width: 30px !important;
      }
      .w-xxl-35px {
        width: 35px !important;
      }
      .w-xxl-40px {
        width: 40px !important;
      }
      .w-xxl-45px {
        width: 45px !important;
      }
      .w-xxl-50px {
        width: 50px !important;
      }
      .w-xxl-55px {
        width: 55px !important;
      }
      .w-xxl-60px {
        width: 60px !important;
      }
      .w-xxl-65px {
        width: 65px !important;
      }
      .w-xxl-70px {
        width: 70px !important;
      }
      .w-xxl-75px {
        width: 75px !important;
      }
      .w-xxl-80px {
        width: 80px !important;
      }
      .w-xxl-85px {
        width: 85px !important;
      }
      .w-xxl-90px {
        width: 90px !important;
      }
      .w-xxl-95px {
        width: 95px !important;
      }
      .w-xxl-100px {
        width: 100px !important;
      }
      .w-xxl-125px {
        width: 125px !important;
      }
      .w-xxl-150px {
        width: 150px !important;
      }
      .w-xxl-175px {
        width: 175px !important;
      }
      .w-xxl-200px {
        width: 200px !important;
      }
      .w-xxl-225px {
        width: 225px !important;
      }
      .w-xxl-250px {
        width: 250px !important;
      }
      .w-xxl-275px {
        width: 275px !important;
      }
      .w-xxl-300px {
        width: 300px !important;
      }
      .w-xxl-325px {
        width: 325px !important;
      }
      .w-xxl-350px {
        width: 350px !important;
      }
      .w-xxl-375px {
        width: 375px !important;
      }
      .w-xxl-400px {
        width: 400px !important;
      }
      .w-xxl-425px {
        width: 425px !important;
      }
      .w-xxl-450px {
        width: 450px !important;
      }
      .w-xxl-475px {
        width: 475px !important;
      }
      .w-xxl-500px {
        width: 500px !important;
      }
      .w-xxl-550px {
        width: 550px !important;
      }
      .w-xxl-600px {
        width: 600px !important;
      }
      .w-xxl-650px {
        width: 650px !important;
      }
      .w-xxl-700px {
        width: 700px !important;
      }
      .w-xxl-750px {
        width: 750px !important;
      }
      .w-xxl-800px {
        width: 800px !important;
      }
      .w-xxl-850px {
        width: 850px !important;
      }
      .w-xxl-900px {
        width: 900px !important;
      }
      .w-xxl-950px {
        width: 950px !important;
      }
      .w-xxl-1000px {
        width: 1000px !important;
      }
  }