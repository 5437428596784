body {
  &.theme-dark {
      .k-button,
      .btn {
          @include flex-combinator(row, nowrap, center, center);
          @include inline-flex;
          padding: calc(.65rem + 1px) calc(1.25rem + 1px);
          font-size: 1rem;
          font-weight: $medium;
          font-family: $font-primary;
          color: $white;
          padding: calc(.75rem + 1px) calc(1.5rem + 1px);

          &.btn-light-primary,
          &.btn-light-primary-reverse-light-only {
            background-color: $primary-dark;
            border-color: $primary-dark;
            color: #a5c4f1;
            &:hover, &:focus, &:hover:not(.btn-active), &:focus:not(.btn-active) {
              color: $white;
              background-color: $primary !important;
              border-color: $primary !important;
            }
          }


          &.btn-light-secondary {
            background-color: $secondary-dark;
            border-color: $secondary-dark;
            color: $secondary;
            &:hover, &:focus {
              color: $white;
              background-color: $secondary;
              border-color: $secondary;
            }
          }

          //Success
          &.btn-light-success,
          &.btn-light-success-reverse-light-only {
            background-color: $success-dark1;
            border-color: $success-dark1;
            color: $success;
            &:hover, &:focus {
              color: $white;
              background-color: $success;
              border-color: $success;
            }
          }


          &.k-button-outline-success,
          &.btn-success-outline {
              color: $success;
              border: 1px solid $success!important;
              background-color: transparent;
              &:hover, &:focus {
                color: $white;
                background-color: $success;
                border-color: $success;
              }
          }

          //Info
          &.btn-light-info {
            background-color: $warning-dark;
            border-color: $warning-dark;
            color: $warning;
            &:hover, &:focus {
              color: $white;
              background-color: $warning;
              border-color: $warning;
            }
          }

          // Warning

          &.btn-light-warning,
          &.btn-light-warning-reverse-light-only {
            background-color: $warning-dark;
            border-color: $warning-dark;
            color: $warning;
            &:hover, &:focus {
              color: $white;
              background-color: $warning;
              border-color: $warning;
            }
          }

          // Danger/Error
          &.btn-light-danger,
          &.btn-light-danger-reverse-light-only {
            background-color: $danger-dark;
            border-color: $danger-dark;
            color: $danger;
            &:hover, &:focus {
              color: $white;
              background-color: $danger;
              border-color: $danger;
            }
          }


          &.k-button-outline-danger,
          &.btn-danger-outline {
              color: $danger;
              border: 1px solid $danger!important;
              background-color: transparent;
              &:hover, &:focus {
                color: $white;
                background-color: $danger;
                border-color: $danger;
              }
          }

          &.k-button-outline-error,
          &.btn-error-outline {
              color: $error;
          }

          // Dark
          &.btn-light-dark {
            background-color: $purple-50;
            border-color: $purple-50;
            color: $light-color;
            &:hover, &:focus {
              color: $secondary-dark;
              background-color: $white;
              border-color: $white;
            }
          }

          &.btn-light,
          &.k-button-solid-light {
            background-color: $dark-btn-bg;
            border-color: $dark-btn-bg;
            color: $secondary-hover;
            &:hover, &:focus {
              background-color: $dark-btn-bg;
              border-color: $dark-btn-bg;
              color: $primary;
              i {
                color: $primary;
              }
            }
          }



      }

    .toggle-fieldset {
      .k-switch-md {
        .k-switch-thumb {
          background-color: $gray-lighter;
        }
      }
    }

    .btn {
      &.btn-light-primary {
        .svg-icon {
          svg {
            [fill]:not(.permanent):not(g) {
              fill: $primary;
            }
          }
        }
        &:hover, &:focus {
          .svg-icon {
            svg {
              [fill]:not(.permanent):not(g) {
                fill: $white;
              }
            }
          }
        }
      }
    }

    .k-switch-on {
      .k-switch-track {
          border-color: $primary;
          background-color: $primary-dark;
          color: $primary;
          transition: all 0.3s ease 0s;
          &:hover, &:focus {
            background-color: #6c757d;
            border-color: #F1416C;
            color: $white;
            transition: all 0.3s ease 0s;
          }
      }
  }
  .btn-delete
  {
    background: #2c2c41;
    border-color: #2c2c41;
    padding: 1px 15px 0px 0px;
    span
    {
      background: #3a2434;
      border-color:#F1416C;
      color:#F1416C;
    }
  }
  .btn-play 
  {
    span
    {
      background: #212E48;
      border-color:#a5c4f1;
      color:#a5c4f1;
    }
  }
  .k-upload-button
  {
    background-color: transparent!important;
    border-color: #a5c4f1!important;
  }
  }
}
