.r-tabs {
  .k-content {
    background-color: transparent;
    // border: 0;
    overflow: hidden;
  }

  .k-tabstrip-items-wrapper {
    // border: 0;
  }

  .k-tabstrip-items {
    .k-item {
      // background-color: transparent;
      // color: $primary;
      // background-color: $primary-light;
      // text-transform: capitalize;
      // border: 0;
      // transition: all 0.3s ease 0s;
      // border-radius: 0;
      // .k-link {
      //   padding: 3px 6px;
      //   font-size: 11px;
      // }
      // &:not(:last-child) {
      //   margin-right: 8px;
      // }
      // .icon {
      //   color: $primary;
      // }
      // &.k-state-active,
      // &.k-state-selected,
      // &:hover, &:focus {
      //     background-color: $primary;
      //     border-color: $primary;
      //     color: $white;
      //     transition: all 0.3s ease 0s;
      //     .icon {
      //         color: $white;
      //     }
      //     &:focus {
      //         background-color: $primary;
      //     }
      // }
      // &:focus {
      //     box-shadow: none;
      //     background-color: transparent;
      // }
    }
  }
}