body,
html {
    font-size: 14px;
    font-weight: 400;
    font-family: $font-primary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .k-appbar {
        font-family: $font-primary;
    }
}

ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
}

.portal-main {
    display: flex;
}

.k-drawer-container {
    align-items: initial;
    @media (min-width: 992px) {
        height: calc(100vh - 75px);
        overflow: hidden;
    }
}

.body-space {
    padding: 2.5rem 0 0;
}

.k-drawer-content {
    height: 100%;
    @include flexbox();
    @include flex-direction(column);
    width: 100%;
    overflow-y: auto;
}

// .k-toolbar {
//     @include flex-combinator(row, wrap, flex-start, flex-start);
//     gap: 0;
// }

.footer-main {
    background-color: white;
    margin-top: auto;
    position: sticky;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    border-top: 1px solid $gray-500;
}

.theme-mode {
    button {
        border: none;
        background-color: transparent;
        display: flex;
        width: 100%;
    }
}

.wrapper {
    min-height: 100vh;
}

// .k-tabstrip-items-wrapper {
//   z-index: 1;
// }

@media (max-width: 991px) {
    .portal-main .sidebar.aside[style="flex-basis: 50px;"] {
        flex-basis: 0px!important;
        border-right: 0;
    }
}

body {
    @media (max-width: 991px) {
        padding-top: 55px;
    }
}
