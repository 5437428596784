.r-togglable {
  &.k-expander {
    border: 0;
    background-color: transparent;
    &.k-state-focus {
      box-shadow: none;
    }
    .k-expander-header {
      padding: 0;
      background-color: transparent;
      border: 0;
    }
    .k-expander-content {
      padding: 0;
      .content {
        padding: 0;
      }
    }
    .k-expander-indicator {
      display: none;
    }
  }
  &.k-focus {
    border: 0;
    box-shadow: none;
  }
}

