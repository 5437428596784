body {
    &.theme-dark {
        background-color: $purple-darker;
        color: $white;
        .text-black {
            color: $purple-30 !important;
        }
        .bottom-border {
            border-bottom-color: $purple-40;
        }
        .top-border {
            border-top-color: $purple-40;
        }
        .border-x {
            border-left-color: $purple-40;
            border-right-color: $purple-40;
        }
        .border-y {
            border-top-color: $purple-40;
            border-bottom-color: $purple-40;
        }
        .border-xy {
            border-color: $purple-40;
        }
        .k-chart-surface {
            text {
                fill: $white-6;
            }
        }
        .performence-chart {
            path {
                stroke: $white-2;
            }
        }
    }
}
