.landing-dark-bg {
    background-color: #13263c;
}
.bgi-size-contain {
    background-size: contain;
}
.bgi-position-x-center {
    background-position-x: center;
}
.bgi-position-y-bottom {
    background-position-y: bottom;
}
.bgi-no-repeat {
    background-repeat: no-repeat;
}


.Dark-Button
{
  background-color:#2F2F4C;
  color: #fff;
  font-size: 20px;
  height: 60px;
  padding: 10px 32px;
  &:hover
  {
    background-color:#404069;
  }
  &.signIn-btn
  {
    background: #2F2F4C url(../../media/landing-page/signIn.svg) no-repeat left;
    background-position-x: 22%;
    padding-left: 60px;
    &:hover
    {
      background: #404069 url(../../media/landing-page/signIn.svg) no-repeat left;
      background-position-x: 22%;
    }
  }
  &.try-btn
  {
    background: #2F2F4C url(../../media/landing-page/try-icon.svg) no-repeat left;
    background-position-x: 16%;
    padding-left: 60px;
    &:hover
    {
      background: #404069 url(../../media/landing-page/try-icon.svg) no-repeat left;
      background-position-x: 16%;
    }
  }
  &.join-btn
  {
    background: #2F2F4C url(../../media/landing-page/join-img.svg) no-repeat left;
    background-position-x: 16%;
    padding-left: 60px;
    &:hover
    {
      background: #404069 url(../../media/landing-page/join-img.svg) no-repeat left;
      background-position-x: 16%;
    }
  }
}

.home-page
{
   color:#506690;
  .landing-slider
  {
    padding-top: 35px;
    background: url(../../media/landing-page/side-blue-circle.svg) no-repeat left top;
    background-position: 0% 8%;
    .landing-text-section
    {
      h5
      {
        color:#01CBA5;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 25px;
      }
      h2
      {
        font-weight: 600;
        font-size: 55px;
        line-height: 70px;
        margin-bottom: 0px;
        &:after
        {
          content: "";
          display: block;
          height: 27px;
          width: 534px;
          background: url(../../media/landing-page/blue-arrow.svg) no-repeat bottom left;
          margin-top: 5px;
        }
        span
        {
          font-size: 55px;
          color:#148BFF;
        }
      }
      p
      {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        margin-top: 80px;
        border-left: #01CBA5 solid 3px;
        padding-left: 20px;
        max-width: 525px;
      }
      button
      {
        margin-top: 30px;
      }
    }
    .landing-image-section-1
    {
      background: url(../../media/landing-page/slide-1-right-image.png) no-repeat right center;
      width: 596px;
      height: 632px;
      display: block;
      position: relative;
      .chat_bubble-1
      {
        display: block;
        position:absolute;
        top: -56px;
        left: 0;
        background: url(../../media/landing-page/chat-bubble-1.svg) no-repeat left center;
        height: 273px;
        width: 414px;
        font-weight: 500;
        font-size:21px;
        line-height: 31px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        p
        {
          width: 340px;
        }
      }
      &::after
      {
        content: "";
        display: block;
        position:absolute;
        bottom: -43px;
        left: -290px;
        background: url(../../media/landing-page/black-arrow-1.svg) no-repeat left center;
        height: 285px;
        width: 280px;
      }
    }
    .landing-image-section-2
    {
      background: url(../../media/landing-page/slide-2-right-image.svg) no-repeat right center;
      width: 627px;
      height: 628px;
      display: block;
      position: relative;
      &::after
      {
        content: "";
        display: block;
        position:absolute;
        bottom: -60px;
        left: -300px;
        background: url(../../media/landing-page/black-arrow-1.svg) no-repeat left center;
        height: 285px;
        width: 280px;
      }
    }
    .landing-image-section-3
    {
      background: url(../../media/landing-page/slide-3-right-image.png) no-repeat right center;
      width: 610px;
      height: 682px;
      display: block;
      position: relative;
      .chat_bubble-2
      {
        display: block;
        position:absolute;
        top: -64px;
        left: -95px;
        background: url(../../media/landing-page/chat-bubble-2.svg) no-repeat left center;
        height: 273px;
        width: 329px;
        font-weight: 500;
        font-size:21px;
        line-height: 31px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 70px;
        p
        {
          width: 277px;
        }
      }
      &::after
      {
        content: "";
        display: block;
        position:absolute;
        top: 160px;
        left: -34px;
        background: url(../../media/landing-page/black-arrow-2.svg) no-repeat left center;
        height: 285px;
        width: 280px;
      }
    }
    .landing-image-section-4
    {
      background: url(../../media/landing-page/slide-4-right-image.svg) no-repeat right center;
      width: 633px;
      height: 685px;
      display: block;
      position: relative;
      .chat_bubble-2
      {
        content: "";
        display: block;
        position:absolute;
        top: -56px;
        left: -85px;
        background: url(../../media/landing-page/chat-bubble-2.svg) no-repeat left center;
        height: 273px;
        width: 329px;
        font-weight: 500;
        font-size:21px;
        line-height: 31px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 70px;
        p
        {
          width: 277px;
        }
      }
      &::after
      {
        content: "";
        display: block;
        position:absolute;
        top: 237px;
        left: -95px;
        background: url(../../media/landing-page/black-arrow-2.svg) no-repeat left center;
        height: 201px;
        width: 175px;
        background-size: 75%;
      }
    }
    .k-scrollview {
      border: none !important;
      .k-scrollview-wrap {
        li {
          padding-top: 30px !important;
        }
      }
    }
  }
  h3
    {
      font-weight: 700;
      font-size: 50px;
      line-height: 60px;
      color:#2F2F4C;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      span
      {
        color:#148BFF;
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        margin-left: 20px;
        position: relative;
      &::before
      {
        content: "";
        background: url(../../media/landing-page/work-circle.svg);
        display: block;
        height: 86px;
        width: 174px;
        position: absolute;
        left: -13px;
        top: -16px;
      }
      }
    }
  .main-heading
  {
    p
    {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .work-text
  {
    border-radius: 26px;
    // border:#E3E6FF solid 0px;
    padding:5px;
    width: 456px;
    position: relative;
    // height: 135px;
    // margin-bottom: 0px;
    // &::after 
    // {
    //   content: '';
    //   position: absolute;
    //   display: block;
    //   width: 0;
    //   z-index: 1;
    //   border-style: solid;
    //   border-width: 0 20px 20px 0;
    //   border-color: transparent #fff transparent transparent;
    //   bottom: -20px;
    //   right: 35px;
    //   margin-left: -10px;
    // }
    // &::before 
    // {
    //   content: '';
    //   position: absolute;
    //   display: block;
    //   width: 0;
    //   z-index: 1;
    //   border-style: solid;
    //   border-width: 0 23px 23px 0;
    //   border-color: transparent #e4e6ff transparent transparent;
    //   bottom: -23px;
    //   right: 34px;
    // }
    p
    {
      font-weight: 500;
      font-size: 20px;
      color:#2F2F4C;
      padding-left: 50px;
    }
    .dot-point
    {
      background:#01CBA5;
      font-weight: 600;
      font-size: 22px;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius:50px;
      width: 40px;
      height: 40px;
      color: #fff;
      margin-bottom: 15px;
      position: absolute;
    }
  }
  .bubble-text
  {
    position: absolute;
    top: 0;
    right: 53px;
    ul
    {
      border:#01CBA5 solid 3px;
      background: #fff;
      box-shadow: 4.3241px 21.6205px 160px rgba(0, 8, 50, 0.06);
      border-radius: 30px;
      width: 251px;
      padding: 35px;
      li
      {
        background: url(../../media/landing-page/hand-icon.svg) no-repeat left;
        padding-left: 50px;
        margin-bottom: 20px;
        &:last-child
        {
          margin-bottom: 0px;
        }
        label
        {
          font-weight: 600;
          font-size: 18px;
          color:#01CBA5;
          line-height: 19px;
        }
        p
        {
          color:#506690;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 0px;
        }
      }
    }
  }
  .demo-container
  {
    background:#F8F8F8 url(../../media/landing-page/demo-image.svg) right top no-repeat;
    border-radius: 35px;
    height: 956px;
    display: block;
    background-size: 61%;
    .demo-text
    {
      padding: 160px 80px;
      position: relative;
      h3
      {
       justify-content: start;
      }
      p
      {
        font-weight:400;
        font-size: 20px;
        width: 442px;
        margin: 20px 0px 30px;
        z-index: 2;
        position: relative;
        &::after
        {
          content: "";
          background: url(../../media/landing-page/demo-black-arrow.svg) no-repeat bottom;
          height: 233px;
          width: 285px;
          display: block;
          position: absolute;
          right: -35px;
          top: 130px;
        }
      }
      .thanks-text
      {
        border-radius: 25px;
        width: 368px;
        padding: 30px;
        background: #fff;
        position: absolute;
        right: 268px;
        bottom: 57px;
        p
        {
          font-weight: 500;
          font-size: 18px;
          display: inline;
          &::after
          {
            display: none;
          }
        }
        a
        {
          font-weight: 600;
          color:#007BF2;
          font-size: 18px;
          margin-top: 15px;
          display: block;
        }
      }
      .press-text
      {
        display: flex;
        border-radius: 25px;
        width: 368px;
        padding: 20px;
        background: #fff;
        position: absolute;
        right: 90px;
        top: 280px;
        label
        {
          font-weight: 600;
          font-size: 25px;
          line-height: 35px;
          margin-left: 20px;
        }
      }
    }
  }
  .work-column
  {
    background: #fff;
    border-radius: 22px;
    width: 380px;
    border:#DFE2FC solid 2px;
    padding:40px;
    height: 570px;
    display: flex;
    flex-direction: column;
    img
    {
      height: 50px;
      width: 50px;
      margin-bottom: 30px;
    }
    label
    {
      font-weight: 600;
      font-size: 30px;
      color: #000;
      line-height: 40px;
      margin-bottom: 15px;
    }
    ul
    {
      margin: 0px;
      padding: 0px;
      li
      {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 15px;
        background: url(../../media/landing-page/tick.svg) no-repeat left;
        padding-left: 30px;
      }
    }
  }

  .pricing-container
  {
    background:#F8F8F8;
    h2
    {
      color:#01CBA5;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }
    p
    {
      font-weight: 700;
      font-size: 50px;
      line-height: 56px;
      color:#2F2F4C;
      margin-bottom: 25px;
      span
      {
        color:#148BFF;
        position: relative;
        &::before
        {
          content: "";
          background: url(../../media/landing-page/pricing-circle.svg) no-repeat center center;
          width: 191px;
          height: 87px;
          position: absolute;
          display: block;
          right: -69px;
          top: -10px;
        }
      }
    }
    label
    {
      font-weight: 700;
      font-size: 50px;
      line-height: 56px;
      color:#148BFF;
      margin-bottom: 20px;
    }
    button
    {
      width: 180px;
    }
    .col-lg-8
    {
      position: relative;
      &::after
      {
        content: "";
        background: url(../../media/landing-page/pricing-arrow.svg) no-repeat left;
        width: 256px;
        height: 229px;
        display: block;
        position: absolute;
        bottom: -61px;
        right: 44px;
      }
    }
    .col-lg-4
    {
      margin-left: -70px;
    }
    ul
    {
      display: block;
      background: #F2F2F2;
      border-radius: 15px;
      padding: 0px 14px;
      width: 183px;
      margin: 0px 10px;
      position: absolute;
      top: 163px;
      left: 350px;
      li
      {
        font-weight: 500;
        font-size: 14.2952px;
        line-height: 28px;
        list-style: none;
        &:before {
          content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: #007BF2; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          font-size: 2em;
          margin-right:8px; /* Also needed for space (tweak if needed) */
        }
      }
      .OkBtn
      {
        background:#148BFF url(../../media/landing-page/white-tick.svg) no-repeat center center;
        height: 33px;
        width: 50px;
        display: block;
        border-radius: 6px;
        border: none;
        float: right;
        margin:10px 0px 13px;
      }
    }
  }
  .business-container
  {
    background: #ECF3FB;
    padding: 60px 0px;
    .col-md-3
    {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      span
      {
        font-size: 55px;
        color: #007BF2;
        line-height: 70px;
      }
      p
      {
        font-size:23px;
      }
    }
  }
}





//Header
.landing-header {
    display: flex;
    align-items: center;
    height: 100px;
    background: #fff;
    .logo-default {
        display: block;
    }
    .logo-sticky {
        display: none;
    }

}
.flex-equal {
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 0;
}

@media (min-width: 992px) {
    .min-h-lg-500px {
        min-height: 500px!important;
    }
}

.landing-dark-bg {
    background-color: #13263c;
  }
  .landing-dark-color {
    color: #13263c;
  }
  .landing-dark-border {
    border: 1px dashed #2c3f5b;
  }
  .landing-dark-separator {
    border-top: 1px dashed #2c3f5b;
  }
  .landing-curve {
    position: relative;
  }
  .landing-curve svg {
    position: relative;
    top: 0;
    display: block;
  }
  .landing-header {
    display: flex;
    align-items: center;
    height: 100px;
  }
  .landing-header .logo-default {
    display: block;
  }
  .landing-header .logo-sticky {
    display: none;
  }
  .landing-header .menu .menu-link.active {
    color: #fff;
  }
  [data-kt-sticky-landing-header="on"] .landing-header .menu .menu-link.active {
    color: #009ef7;
    background-color: rgba(239, 242, 245, 0.4);
  }
  [data-kt-sticky-landing-header="on"] .landing-header {
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.05);
    height: 70px;
  }
  [data-kt-sticky-landing-header="on"] .landing-header .logo-sticky {
    display: block;
  }
  [data-kt-sticky-landing-header="on"] .landing-header .logo-default {
    display: none;
  }
  body[data-kt-sticky-landing-header="on"] {
    padding-top: 100px;
  }
  @media (max-width: 991.98px) {
    .landing-header {
      height: 70px;
    }
    .landing-header .landing-menu-wrapper {
      position: relative;
      overflow: auto;
    }
    [data-kt-sticky-landing-header="on"] .landing-header {
      height: 70px;
    }
    .landing-header .menu .menu-link.active {
      color: #009ef7;
      background-color: rgba(239, 242, 245, 0.4);
    }
    body[data-kt-sticky-landing-header="on"] {
      padding-top: 70px;
    }
  }


  .k-scrollview
  {
    font-family:$font-primary!important;
    .k-scrollview-wrap
    {
      white-space:normal;
    }
    p
    {
      color: #506690;
    }
    .landing-image-section-1
    {
      p
      {
        font-size: 20px;
      }
    }
  }

  .our-client-say
  {
    background: #FEFEFE;
    position: relative;
    padding: 170px 0px;
    &::before
    {
      content: "";
      background: url(../../media/landing-page/top-arrow.svg) no-repeat center center;
      position:absolute;
      width: 107px;
      height: 85px;
      display: block;
      left: 50px;
    top:100px;
    }
    &::after
    {
      content: "";
      background: url(../../media/landing-page/bottom-arrow.svg) no-repeat center center;
      position:absolute;
      width: 107px;
      height: 85px;
      display: block;
      right: 50px;
      bottom: 0px;
    }
    .client-card
    {
      background: #fff;
      border-radius: 30px;
      padding: 40px;
      box-shadow: 4.3241px 21.6205px 160px rgba(0, 8, 50, 0.06);
      .stars-bar
      { 
        .star {
          position: relative;
          
          display: inline-block;
          width: 0;
          height: 0;
          
          margin-left: .9em;
          margin-right: .9em;
          margin-bottom: 1.2em;
          
          border-right:  .3em solid transparent;
          border-bottom: .7em  solid #FC0;
          border-left:   .3em solid transparent;
        
          /* Controlls the size of the stars. */
          font-size: 10px;
          margin-bottom: 20px;
          
          &:before, &:after {
            content: '';
            
            display: block;
            width: 0;
            height: 0;
            
            position: absolute;
            top: .6em;
            left: -1em;
          
            border-right:  1em solid transparent;
            border-bottom: .7em  solid #FC0;
            border-left:   1em solid transparent;
          
            transform: rotate(-35deg);
          }
          
          &:after {  
            transform: rotate(35deg);
          }
        }
      }
      h3
      {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        justify-content: start;
      }
      p
      {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        span
        {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #2F2F4C;
        }
      }
      .info-text
      {
        h4
          {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
          }
        span
        {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .start-today
  {
    background:#F8F8F8;
    h3
    {
      &::after
      {
        content: "";
        background: url(../../media/landing-page/ray-arrow.svg) no-repeat center center;
        height: 60px;
        width: 69px; 
        display: block;
        margin-top: -26px;
        margin-left: -26px;
      }
    }
  }
  .getStarted
  {
    background:#ECF3FB;
    padding: 100px;
    .letColumn
    {
      height: 112px;
      background-color: #fff;
      border-radius: 18px;
      padding: 24px 40px;
      p
      {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #2F2F4C;
        margin-bottom: 5px;
      }
      span
      {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color:#506690;
        a
        {
          color:#007BF2;
          font-weight: 700;
        }
      }
    }
  }

  .homeFooter
  {
    padding: 170px 0px;
    background: url(../../media/landing-page/footer-Img.svg) no-repeat right bottom;
   
    .menuLink
    {
      padding: 40px 0px;
      a
      {
        color:#506690;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        border-right:#A5A9BA solid 1px;
        padding:0px 25px;
        text-transform: uppercase;
      }
    }
    .socialLinks
    {
      display: flex;
      justify-content: center;
      padding:40px 0px;
      position: relative;
      &::before
      {
        content: "";
        width: 90px;
        height: 2px;
        position: absolute;
        top: 0;
      }
      .Facebook
      {
        background: url(../../media/landing-page/FB.svg) no-repeat center center;
        width: 15px;
        height: 27px;
        display:block;
        margin:0px 20px;
      }
      .twitter
      {
        background: url(../../media/landing-page/twitter.svg) no-repeat center center;
        width: 24px;
        height: 27px;
        display:block;
        margin:0px 20px;
      }
      .instagram
      {
        background: url(../../media/landing-page/instagram.svg) no-repeat center center;
        width: 31px;
        height: 30px;
        display:block;
        margin:0px 20px;
      }
      .linkedIn
      {
        background: url(../../media/landing-page/In.svg) no-repeat center center;
        width: 21px;
        height: 27px;
        display:block;
        margin:0px 20px;
      }
    }
    p
    {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      span
      {
        color:#2F2F4C;
        font-weight: 700;
        font-size: 20px;    
      }
    }
  }

 