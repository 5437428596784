#edit-form {
  &.collapsing ~ .greeting-content {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }

  &.show ~ .greeting-content {
    display: none;
    height: 0;
    overflow: hidden;
  }
}

.greeting-content {
  transition: height 0.35s ease;
  height: auto;
}

.k-textarea {
  .k-input-inner {
    min-height: 200px;
  }
}

.calls-detail-table {
  .toggle-fieldset {
    .k-form-field {
      width: 100%;
      &.toggle-fieldset-btn {
        width: 50px;
      }
    }
  }
}

.text-input-row {
  // max-width: 700px;
  position: relative;

  .relaygrid-form {
    width: 100%;
    margin: 0;
  }

  .toggle-fieldset {
    width: 100%;
  }
}

.r-togglable.custom-header-content {
  &.k-expander {
    .k-expander-header {
      position: absolute;
      right: 15px;
      top: 0;
      z-index: 1;
      height: 76px;
      width: 62px;
      @include flex-combinator(row, wrap, center, flex-end);
    }
  }
}

.row-view-only {
  .menuTypeRow {
    .k-form-fieldset {
      input,
      .k-input-inner {
        pointer-events: none;
        padding: 0 0 0 40px !important;
        transition: all 0.3s ease 0s;
      }

      &.toggle-fieldset-btn {
        display: none;
        transition: all 0.3s ease 0s;
      }

      .k-label,
      .k-clear-value,
      .field-reset,
      .file-delete {
        display: none !important;
        transition: all 0.3s ease 0s;
      }

      &.custom-icon {
        &.custom-upload {
          .field-icon {
            pointer-events: none;
            transition: all 0.3s ease 0s;
          }
        }
      }
    }

    .k-upload-button,
    .field-icon {
      pointer-events: none;
      transition: all 0.3s ease 0s;
    }

    .k-dropzone {
      .k-upload-button {
        display: none !important;
        transition: all 0.3s ease 0s;
      }

      .k-dropzone-hint {
        font-size: 0;
        padding-left: 0 !important;
        transition: all 0.3s ease 0s;
      }
    }
  }
}

.smsTextarea {
  .k-input-inner {
    min-height: 80px;
  }
}

.menuTypeRow {
  .digit {
    .symbol-label {
      position: relative;
      width: 50px;
      padding: 0 6px;
      cursor: grabbing;

      &:before {
        content: "";
        width: 4px;
        height: 100%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0.475rem;
      }
    }

    .drag-icon {
      max-height: 15px;
      margin-left: 3px;
      fill: $secondary-medium;
    }
  }

  &[title="sms"] {
    .digit {
      .symbol-label {
        &:before {
          background: $primary;
        }
      }
    }
  }

  &[title="mms"] {
    .digit {
      .symbol-label {
        &:before {
          background: #80deea;
        }
      }
    }
  }

  &[title="queue"] {
    .digit {
      .symbol-label {
        &:before {
          background: #ffde1d;
        }
      }
    }
  }

  &[title="repeat"] {
    .digit {
      .symbol-label {
        &:before {
          background: #6c32ad;
        }
      }
    }
  }

  &[title="prompt"] {
    .digit {
      .symbol-label {
        &:before {
          color: white;
          background: #e81894;
        }
      }
    }
  }

  &[title="vmail"] {
    .digit {
      .symbol-label {
        &:before {
          background: #f1416c;
        }
      }
    }
  }

  &[title="forward"] {
    .digit {
      .symbol-label {
        &:before {
          background: #3fcb80;
        }
      }
    }
  }

  &[title="link"] {
    .digit {
      .symbol-label {
        &:before {
          background: $neon-pink;
        }
      }
    }
  }
}

.mp3-player {
  &.pause {
    .btn-icon {
      &:before {
        content: "\f04c";
      }
    }
  }
}

.menuItemSelector {
  background-color: transparent;
  border: 0;
  width: 100%;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  .k-input-button {
    display: none !important;
  }

  .k-input-inner {
    padding: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: transparent;
    }

    .k-input-value-text {
      padding: 0;
      background-color: transparent;
      border: 0;
      @include flexbox;

      .nav-icon {
        img {
          margin-top: -6px;
        }
      }
    }
  }
}

.nav.nav-pills {
  &.nav-pills-custom {
    .nav-link,
    .show > .nav-link {
      border-width: 0;
    }
  }
}

.menu-option-table {
  @include flex-combinator(column, wrap, flex-start, flex-start);
  .mo-table-head {
    @include flex-combinator(column, wrap, flex-start, flex-start);
    background-color: $gray-400;
    border: 0;
    margin-bottom: 0.625rem;
    font-weight: $semi-bold;
    color: $black-1;
    > tr {
      @include flex-combinator(row, nowrap, center, flex-start);
      width: 100%;
      border: 0;
      position: relative;
      th {
        border: 0;
      }
    }
  }
  .mo-table-head,
  .mo-table-body {
    @include flex-combinator(column, wrap, flex-start, flex-start);
    width: 100%;
    .menusOptionForm {
      > tr {
        @include flex-combinator(row, nowrap, center, flex-start);
        width: 100%;
        border: 0;
        position: relative;

        > th,
        > td {
          box-sizing: border-box;
          padding: 0 10px;
          border: 0;

          &:first-child {
            padding-left: 10px;
          }

          &:last-child {
            padding-right: 10px;
          }
        }
      }
    }
  }

  &.calls-detail-table {
    thead {
      .digit {
        &:before,
        &:after {
          display: none;
        }
      }
    }

    // kendo-sortable {
    //   width: 100%;

    //   >div {
    //     padding: 0;
    //     border-bottom: 0;
    //     position: relative;

    //     &:hover {
    //       tr {
    //         background-color: transparent;
    //       }
    //     }
    //   }
    // }

    .check {
      width: 45px;
      padding: 0.5rem 0.8rem;
    }

    .digit {
      width: 70px;
    }

    .logo {
      width: 77px;
    }

    .say {
      flex: 1;
    }

    .type {
      width: 200px;
    }

    .action {
      margin-left: auto;
    }
  }
}

.togglable-row {
  margin-top: 0.2rem;

  .item-collapsable-table {
    padding-top: 1rem;
  }
}

.menu-option-table {
  &.custom-toogle {
    .action {
      padding-left: 67px;
    }
  }
}

.menusOptionForm {
  padding: 10px 5px 15px;
  border: 1px solid $primary;
  position: relative;
  transition: all 0.3s ease 0s !important;
  margin-top: -1px;

  &.row-view-only {
    padding: 10px 5px;
    border-style: dashed;
    border-width: 1px;
    border-color: transparent transparent $light-theme-border;
    margin: 0;
    transition: all 0.3s ease 0s !important;
  }

  .k-dropzone-hint:before {
    margin-top: 0px !important;
  }
}
.waitqueue {
  display: flex;
  flex-wrap: nowrap;
  -moz-flex-direction: row;
  flex-direction: row;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  width: 100%;
  border-radius: 0.575rem;
  .form-group {
    width: 100%;
  }
  .mp3Player {
    height: 35px;
  }
  .btn-delete {
    height: 35px;
  }
  .custom-upload .form-group {
    height: 35px;
    border-radius: 5px;
  }
  .k-dropzone {
    background-color: transparent;
  }
  .k-upload-button {
    padding: 0px 8px 0px;
    background: transparent;
  }
}

// TODO: This is temporary, needs to be fixed --Swati
.waitqueue .form-group.upload-fieldset > div {
  width: 100%;
}

.custom-upload .k-dropzone {
  height: 100%;
}
// TODO: This is temporary, needs to be fixed --Swati

.upDownButton {
  .btn {
    .icon {
      line-height: 0.7;
    }
  }
}

.textarea {
  .fa-music {
    display: none !important;
  }
}

.textarea-height {
  .k-input-inner {
    min-height: auto;
  }
}

.k-grid-table {
  .mp3Player {
    height: 35px;
  }
}

// span.k-button-text span
// {
//   margin-left: 6px;
// }
