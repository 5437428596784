.portal-main {
  .k-drawer {
    font-family: $font-primary;
  }

  .sidebar {
    background-color: $purple-dark;
    color: $white;
    padding: 0;

    @media (min-width: 992px) {
      min-height: calc(100vh - 74px);
      overflow-y: auto;
      padding-bottom: 86px;
    }

    @media (max-width: 991px) {
      position: fixed;
      z-index: 99999;
      height: 100vh;
      border: 0 !important;
      overflow-y: auto;
      padding-bottom: 80px;

      &[style="flex-basis: 50px;"] {
        flex-basis: 0 !important;

        .k-drawer-wrapper {
          width: 0 !important;
        }
      }
    }

    .k-drawer-wrapper {
      max-width: 100%;
    }

    .aside-search {
      .search-icon {
        left: 0;
      }
    }

    ul {
      .k-drawer-item {
        opacity: 0.6;
        font-size: $font-size;
        width: 100%;
        border-radius: .475rem;
        padding: 8px 20px;
        font-weight: $light;
        line-height: 1.3;
        @include flex-combinator(row, nowrap, center, start);
        &.k-level-0 {
          &.k-state-selected, &:focus {
            background-color: transparent;
          }
        }

        &.k-level-1 {
          width: calc(100% - 40px);
          transform: translateX(20px);
        }

        &:hover {
          background-color: transparent;
          color: $white;
          opacity: 1;
        }

        &.k-state-selected, &.k-selected, &:focus {
          background-color: $primary;
          opacity: 1;
          box-shadow: none;
        }

        .k-icon {
          margin-right: 8px;
          line-height: 1;
          &.k-drawer-toggle {
            margin-left: auto;
            margin-right: 0;
          }
        }


      }
    }

    &.aside {
      .aside-toolbar {
        .aside-user {
          padding: 0 20px;
          border-top: 1px solid $gray-dark;
        }

        .aside-search {
          padding: 0 20px;
          border-top: 1px solid $gray-dark;
          border-bottom: 1px solid $gray-dark;

          .search-input {
            background-color: $black-medium;
            border-color: $black-medium;
            color: $white;
          }
        }
      }

      .aside-footer {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 20px;
        // padding: 0 0 0 20px;
      }

      &[style="flex-basis: 50px;"] {
        ul {
          .k-drawer-item {
            @include align-items(center);
            @include justify-content(center);

            .k-icon {
              margin: 0;
            }

            .k-level {
              padding: 0;
              justify-content: center;
            }
          }
        }

        // .aside-footer {
        //   padding: 0 3px;

        //   .k-icon {
        //     margin: 0 !important;
        //   }
        // }
      }

      &[style="flex-basis: 50px;"]~.aside-minimize .minimize-default {
        transform: scale(0);
        transition: 0.3s;
      }

      &[style="flex-basis: 266px;"]~.aside-minimize .minimize-active {
        transform: scale(0);
        transition: 0.3s;
      }
    }
  }
}
