body {
    &.theme-dark {

        //Dashed borders
        .border-bottom-dashed {
            border-bottom: 1px dashed $info-dark1  !important;
        }

        //Solid borders
        .border-bottom-solid {
            border-bottom: 1px solid $info-dark1  !important;
        }
    }
}