body {
  &.theme-dark {
    .call {
      &.light-theme {
        display: none;
      }
      &.dark-theme {
        display: block;
      }
    }

    .text-muted {
      color: $dark-theme-text-color !important;
    }

    .menu-gray-600 {
      .menu-item {
        .menu-link {
          color: $dark-theme-text-color;
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
