.k-breadcrumb {
    background-color: transparent;
    
    .k-breadcrumb-item {
        font-family: $font-primary;
        .k-cursor-pointer {
            padding: 0.3rem 0;
            text-decoration: none;
        }
        &.k-breadcrumb-last-item {
            
        }
    }
}