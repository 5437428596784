.k-badge-solid {
    &.k-badge-primary {
        background-color: $primary;
        border-color: $primary;
    }
    &.k-badge-success {
        background-color: $success;
        border-color: $success;
    }
    &.k-badge-error {
        background-color: $danger;
        border-color: $danger;
    }
}
