.dash-top-cards {
  .card {
    .card-body {
      overflow: hidden;
      height: 140px;
      @include flex-combinator(row, wrap, center, start);
      padding-bottom: 0;
    }
  }
  .info-chart {
    margin-left: -26px;
    margin-top: -34px;
    height: auto;
  }
}

.donut-chart {
    height: auto;
    >kendo-chart-crosshair-tooltips-container,
    >kendo-chart-tooltip-popup,
    >kendo-resize-sensor {
        display: none;
    }
    .k-chart-surface {
        >svg {
            width: 100px!important;
            height: 100px!important;
        }
    }


}
.info-chart {
  svg {
    path {
      stroke-width: 4px !important;
    }
    text {
      font-weight: $medium!important;
      font-family: $font-primary!important;
      margin-left: 10px !important;
      text-transform: capitalize;
    }
  }
}

.card-right {
    min-width: 250px;
}

.k-chart-surface {
    >svg {
        >g {
            >path {
                display: none;
            }
        }
    }
}

.custom-width {
    .col-xxl-6 {
        @media (min-width: 1401px) and (max-width: 1600px) {
            width: 100%;
        }
    }
}

.call {
  &.light-theme {
    display: block;
  }
  &.dark-theme {
    display: none;
  }
}

.aside-minimize {
  &.btn {
    &.btn-light-primary {
      &:hover, &:focus,
      &:hover:not(.btn-active) {
        background-color: transparent !important;
      }
    }
  }
}
