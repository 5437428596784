body {
  &.theme-dark {

    .k-grid {
      background-color: $gray-dark;
      color: $white;
    }

    table {
      color: $white;
    }

  }
}