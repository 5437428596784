body {
    &.theme-dark {
        .footer {
            background-color: black;
        }
        .footer-main {
            background-color: $purple-dark;
            margin-top: auto;
            position: sticky;
            bottom: 0;
            right: 0;
            width: 100%;
            z-index: 3;
            border-top: 1px solid $gray-dark;
        }
        
    }
}

