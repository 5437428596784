body {
  &.theme-dark {
    .user-avtar {
      .k-dropzone {
          .k-button {
              margin-top: 0.5rem;
              @extend .btn-light-primary;
          }
      }
  }
  }
}
