body {
    
    padding: 0;
    margin: 0 auto;
    color: $r-black;
}

.full-width{
  width: 100%;
}

a {
    text-decoration: none;
}
img {
  max-width: 100%;
}
.fontawesome5 {
    font-family: 'Font Awesome 5 Free';
}

.border-0, .border-none {
  border: none !important;
}
.bottom-border {
    border-bottom: 1px dashed $light-color;
}

.top-border {
    border-top: 1px dashed $light-color;
}

.border-x {
    border-width: 0 1px 0 1px;
    border-style: dashed;
    border-color: $light-color;
}

.border-y {
    border-width: 1px 0 1px 0;
    border-style: dashed;
    border-color: $light-color;
}

.border-xy {
    border: 1px dashed $light-color;
}

.width-full {
    width: 100%;
}

.svg-icon {
    &.svg-icon-success,
    &.svg-icon-success svg [fill]:not(.permanent):not(g) {
        fill: $success;
    }
}

.k-appbar-light {
  background-color: $white;
}

.flex-center {
  @include align-items(center);
  @include justify-content(center);
}
.flex-stack {
  @include justify-content(space-between);
  @include align-items(center);
}


.z-index-n1 {
  z-index: -1 !important;
}
.z-index-n2 {
  z-index: -2 !important;
}
.z-index-0 {
  z-index: 0 !important;
}
.z-index-1 {
  z-index: 1 !important;
}
.z-index-2 {
  z-index: 2 !important;
}
.z-index-3 {
  z-index: 3 !important;
}


//Font Weight
.fst-italic {
    font-style: italic !important;
  }
  .fst-normal {
    font-style: normal !important;
  }
  .fw-light {
    font-weight: 300 !important;
  }
  .fw-lighter {
    font-weight: lighter !important;
  }
  .fw-normal {
    font-weight: 400 !important;
  }
  .fw-bold {
    font-weight: 500 !important;
  }
  .fw-bolder {
    font-weight: 600 !important;
  }


  // .k-icon {
  //   &.fa-duotone {
  //     font-family: 'Font Awesome 6 Duotone';
  //     font-style: normal;
  //     font-weight: 900;
  //     font-display: block;
  //   }
  // }


  .k-tabstrip-content:focus, .k-tabstrip-content.k-state-focused, .k-tabstrip-content.k-focus, .k-tabstrip > .k-content:focus, .k-tabstrip > .k-content.k-state-focused, .k-tabstrip > .k-content.k-focus {
    outline: 0;
  }

  .header
  {
    position: relative;
    z-index:2;
  }
  .subscription-tiles
  {
    justify-content: center;
    .col-xl-4
    {
      width:330px
    }
  }
