.menu, .menu-wrapper {
    @include flexbox;
    padding: 0;
    margin: 0;
    list-style: none;
}
.menu-item {
    display: block;
    padding: 0;
    .menu-link {
        cursor: pointer;
        @include flexbox;
        @include align-items(center);
        flex: 0 0 100%;
        padding: .65rem 1rem;
        transition: none;
        outline: 0!important;
        color: #506690;
        font-size: 14px;
        font-weight: 500;
        &:hover:not(.disabled):not(.active):not(.here) {
            color: $primary;
        }
    }
}
.menusOptionForm
{
    .px-3
    {
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    form
    {
        .px-3 
        {
            padding-right: 0.75rem !important;
            padding-left: 0.75rem !important;
        }
    }
}
.k-button-solid-base:focus
{
    box-shadow: none;
}

// .k-button-solid-base {
//     border-color: #F5F8FA;
//     color: #212529;
//     background-color: #0d6efd;
// }

.k-button-solid-base:hover, .k-button-solid-base.k-hover {
    border-color: #0d6efd;
    background-color: #0d6efd;
}