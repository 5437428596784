body {
    &.theme-dark {
        .text-black {
            color: $purple-30 !important;
        }
        .text-gray-400 {
          color: $dark-theme-text-color !important;
        }
        .border-gray-300 {
          border-color: $purple-lighter !important;
        }
    }
}
