.details-layout {
    .k-tabstrip-top {
        >.k-tabstrip-items-wrapper {
            .k-item {
                background-color: transparent;
                border-width: 0 3px 1px 0;
                border-color: $gray-400;
                border-right-color: transparent;
                margin: 0;
                border-radius: 0;
                &.k-state-active {
                    border-right-color: $primary;
                    border-bottom-color: $gray-400;
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
    .k-tabstrip-items-wrapper {
        border-width: 0 1px 0 0;
        border-style: solid;
        border-color: $gray-400;
    }
    .pane-top {
        border: 0;
        background-color: transparent;
        width: 100%!important;
        padding: 0 1rem;
        kendo-card-header {
            border-style: dashed;
            border-width: 0 0 1px 0;
            border-color: $light-color;
            background-color: transparent;
        }
    }
    .k-tabstrip-items {
        .k-link {
            padding: 1rem 1rem 0.5rem;
        }
    }
}

.mView {
    @include flex-direction(row);
    height: 85.8vh;
    .k-content {
        padding: 0;
        background-color: transparent;
        border: 0;
        .k-pane {
            @include flexbox;
            max-height: 85.8vh;
            overflow: auto;
            .pane-content {
                margin-top: auto;
                width: 100%;
                >label {
                    display: none;
                }
                .k-chat {
                    height: 85.8vh;
                    max-width: 100%;
                    background-color: transparent;
                    border: 0;
                    padding: 0 1rem;
                    .k-message-group {
                        max-width: 550px;
                        margin: 0 auto;
                    }
                    .k-message {
                        margin-top: 1rem;
                    }
                    .k-message-box {
                        width: 100%;
                        margin: 0 auto;
                        position: relative;
                        background-color: transparent;
                        border-style: dashed;
                        border-color: $light-color !important;
                        &:before {
                            content: '\f03e';
                            font-family: FontAwesome;
                            padding: 0.375rem 0.75rem;
                        }
                        .k-textbox {
                            width: 100%;
                            padding: 1rem;
                            &:focus {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    .k-tabstrip-items-wrapper {
        ul {
            @include flex-direction(column);
            li {
                .tab-title {
                    display: none;
                }
            }
        }
    }
    .k-splitter {
        min-height: 85.8vh;
        background-color: $white;
        border: 0;
    }
}

.dialerpad {
    display: flex;
    flex-flow: row wrap;
    width: 180px;
    margin: 0 auto 1rem;
    .noItem {
        border-radius: 50%!important;
        width: 60px;
        height: 60px;
        background: transparent;
        border: 0;
        .k-button-text {
            @include flex-combinator(column, nowrap, center, center);
            font-size: 1.8rem;
            line-height: 1;
            .alpha {
                font-size: 0.9375rem;
                text-transform: uppercase;
                opacity: 0.6;
            }
        }
        &:hover,
        &:focus {
            background-color: $gray-lighter;
            box-shadow: none;
            outline: 0;
            border: 0;
        }
    }
}

.k-panelbar {
    &.tooglable-keypad {
        padding: 1rem 0 30px;
        border-width: 1px 0 0 0;
        margin: 0 auto;
        position: relative;
        min-width: 180px;
        .k-item {
            .k-link {
                background-color: transparent;
                border: 0;
                color: $black;
                position: absolute;
                bottom: 0;
                width: 100%;
                cursor: pointer;
                @include flex-combinator(row, nowrap, center, center);
                .k-panelbar-toggle {
                    @include flex-combinator(row, nowrap, center, space-between);
                    width: 100%;
                    max-width: 250px;
                    &:before {
                        font-family: $font-primary;
                        font-size: 1rem;
                        left: 0;
                    }
                    &:after {
                        font-family: FontAwesome;
                    }
                    &.k-panelbar-collapse {
                        &:before {
                            content: 'Hide Keypad';
                        }
                        &:after {
                            content: '\f103';
                        }
                    }
                    &.k-panelbar-expand {
                        &:before {
                            content: 'Show Keypad';
                        }
                        &:after {
                            content: '\f102';
                        }
                    }
                }
                &:hover,
                &:focus {
                    background-color: transparent;
                    border: 0;
                    box-shadow: none;
                }
            }
        }
    }
}

.k-splitbar {
    background-color: $gray-400;
    color: $gray-dark;
}

.k-ghost-splitbar-horizontal,
.k-splitbar-horizontal {
    width: 10px;
}

.k-ghost-splitbar,
.k-splitbar {
    .k-icon {
        font-size: 18px;
    }
}

.k-panelbar {
    background-color: transparent;
    color: $black;
    border-color: $gray-400;
    border-style: dashed;
}

.k-bubble {
    @include flex-combinator(column, wrap, flex-start, flex-start);
    word-break: break-all;
}

.left-pane {
    padding: 0 1rem;
}

.right-pane {
    .pane-content {
        @include flex-combinator(column, wrap, flex-start, space-between);
    }
    .dailer {
        @include flex-combinator(row, nowrap, center, space-between);
        .k-form-fieldset {
            .k-input {
                background-color: transparent;
                border: 0;
                .k-input-inner {
                    background-color: transparent;
                    border: 0;
                    box-shadow: none;
                    height: 36px;
                    padding: 0.5rem 0;
                    width: 136px;
                    font-size: 1rem;
                    @include flex-combinator(row, nowrap, center, flex-start);
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
        .dailer-btn {
            height: 36px;
            width: 80px;
            border-radius: 80px;
            margin-left: 0.5rem;
            min-height: auto;
            @include flex-combinator(row, nowrap, center, center);
            padding: 0!important;
            font-size: 20px;
        }
    }
}

.paneitemlist {
    .panelistitem {
        @include flex-combinator(row, nowrap, center, flex-start);
        padding: 0.8rem 0;
        border-bottom: 1px dashed $theme-border;
        .list-icon {
            @include round(1.8rem);
            background-color: $primary;
            margin-right: 0.75rem;
            padding: 0.3rem;
            color: $white;
            flex-basis: auto;
            font-size: 14px;
        }
        .detail {
            @include flex-combinator(column, nowrap, flex-start, flex-start);
            overflow: hidden;
            .status {
                text-transform: capitalize;
                &:before {
                    font-family: "WebComponentsIcons";
                    font-size: 1.3rem;
                    line-height: 1;
                    position: relative;
                }
                &.status-ringing {
                    &:before {
                        content: '\e01c';
                        color: $danger;
                    }
                }
                &.status-completed {
                    &:before {
                        content: '\e127';
                        color: $success;
                        top: 3px;
                    }
                }
            }
            .textbody {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}
