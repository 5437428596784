// .k-grid {
//     background-color: $white;
//     border: none;
//     box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
//     border-radius: .475rem;
//     padding: 1.25rem 1.6rem;
// }
// .k-grid-toolbar {
//     border: 0;
// }
// .k-toolbar {
//     background-color: transparent;
// }

//todo: commenting out until Swati gets back 
// .k-input, .k-picker, .k-toolbar .k-input, .k-toolbar .k-picker {
//     width: initial;
// }

.k-input-inner {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    background-color: #F5F8FA;
    color: #5E6278;
    background-clip: padding-box;
    border: 1px solid #F5F8FA;
    appearance: none;
    border-radius: 0.475rem;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}