body {
    &.theme-dark {
        .k-grid {
            .k-grid-header,
            .k-grid-pager {
                background-color: transparent;
                color: $white;
                border-color: $info-dark1;
            }
            th,
            td {
                border-color: $info-dark1;
            }
        }
    }
}
