.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8080802b;
  height: 100%;
}

// .recurly-element-card {
//   height: 45px;
//   background-color: $light-color;
//   border-color: $light-color;
//   font-family: $font-primary;
// }
// // .recurly-hosted-field-input {
// //   height: 45px;
// //   padding: 6px 10px;
// //   background-color: $light-color;
// //   border-color: $light-color;
// //   font-family: $font-primary;
// //   &:not(:last-child) {
// //     margin-right: 1.5rem;
// //   }
// // }

// .recurly-hosted-field-input-number {
//   border-right: 5px solid white !important;
// }

// .recurly-hosted-field-input-expiry {
//   border-right: 5px solid white !important;
//   border-left: 5px solid white !important;
//   padding-left: 5px;
// }

// .recurly-hosted-field-input-cvv {
//   border-left: 5px solid white !important;
//   padding-left: 5px !important;
// }
