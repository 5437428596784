body {
    &.theme-dark {
        .r-tabs {
            .k-content {
                border-color: $purple-30;
            }
            .k-tabstrip-items-wrapper {
                border-color: $purple-30;
            }
            .k-tabstrip-items {
                .k-item {
                    color: $white-6;
                    .icon {
                        color: $white-6;
                    }
                    &.k-state-active,
                    &.k-state-selected {
                        background-color: $primary;
                        border-color: $primary;
                        color: $white;
                        .icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
