body {
    &.theme-dark {
      .k-pager-nav.k-link, .k-pager-refresh {
        background-color: $primary-dark;
        border-color: $white-2;
        color: $primary;
        transition: all 0.3s ease 0s;
        &:hover, &:focus {
          box-shadow: none;
          background-color: $primary;
          border-color: $primary;
          color: $white;
          transition: all 0.3s ease 0s;
        }
    }
        .k-pager-numbers {
            .k-link {
                &.k-state-selected {
                    border-color: $primary;
                    background-color: $primary;
                    color: $white;
                }
            }
        }
        .k-grid-pager {
            color: $white;
        }
    }
}
