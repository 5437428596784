// .btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
//   border: 1px solid transparent;
// }

.btn {
  min-height: 45px;
  border-radius: 20px;
  font-size: 12px;
  &.btn-small {
      min-height: auto;
      padding: 4px 6px!important;
      font-size: 11px;
  }
  &.btn-medium {
      min-height: auto;
      padding: 4px 8px!important;
  }
  &.btn-large {
      min-height: auto;
      padding: 6px 8px!important;
  }
  &.btn-base {
      background-color: $gray-lighter;
      border-color: $gray-lighter;
      color: $r-black;
      &:hover,
      &:focus {
          background-color: $gray-lighter;
          border-color: $gray-lighter;
          color: $primary;
      }
  }
  &.btn-form {
      min-height: 45px;
  }
  &.btn-icon-small {
    @include sequre(20px);
    border-radius: 4px;
    min-height: auto;
    padding: 0 !important;
    font-size: 10px;
  }

  &.btn-icon {
    @include flex-combinator(row, wrap, center, center);
    padding: 0 !important;
    line-height: 1;
    min-height: initial;    
    &.icon-35 {
      @include rounded(50px, -3.7249rem);
      width: 80px; /* add width property and set value */
      height: 35px; /* add height property and set value */
      font-size: 12px !important;

    }
    &.icon-45 {
      @include rounded(45px, 0.475rem);
      font-size: 18px !important;
    }
  }
}

.toggle-fieldset {
.k-switch-md {
  .k-switch-thumb {
    background-color: $gray;
  }
}
}

.k-switch-on {
  .k-switch-track {
      border-color: #107c10;
      background-color:#107c10;
      color: #ffffff;
      transition: all 0.3s ease 0s;
      &:hover, &:focus {
        background-color: #107c10;
        border-color: #107c10;
        color: #ffffff;
        transition: all 0.3s ease 0s;
      }
  }
}

.k-switch-md {
  width: 40px;
  height: 20px;
  .k-switch-track {
      width: 40px;
      height: 20px;
  }
  .k-switch-thumb {
      @include sequre(26px);
  }
  &.k-switch-off {
      .k-switch-thumb-wrap {
          left: 11px;
      }
      &.k-focus,
      &:focus {
          .k-switch-track {
              box-shadow: none;
              outline: 0;
              border-color: #6c757d;
              color: #6c757d
          }
      }
  }
  &.k-switch-on {
      .k-switch-thumb-wrap {
          left: calc(100% - 11px);
      }
      &.k-focus,
      &:focus {
          .k-switch-track {
              box-shadow: none;
              outline: 0;
              border-color: #f7f6f6;
              color: white;
          }
      }
  }
}

.k-button,
.btn {
  @include flex-combinator(row, nowrap, center, center);
  @include inline-flex;
  padding: calc(.65rem + 1px) calc(1.25rem + 1px);
  font-size: 1rem;
  font-weight: $medium;
  font-family: $font-primary;
  // color: $white;
  // padding: calc(.75rem + 1px) calc(4.5rem + 1px);
  &.k-button-solid-primary,
  &.btn-primary {
      background-color: $primary;
      border-color: $primary;
      &:hover, &:focus {
        color: $white;
        border-color: $primary-hover;
        background-color: $primary-hover;
      }
  }

  &.btn-light-primary {
    background-color: $primary-light;
    border-color: $primary-light;
    color: $primary;
    &:hover, &:focus, &:hover:not(.btn-active), &:focus:not(.btn-active) {
      color: $white;
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }

  &.btn-light-primary-reverse-light-only {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    &:hover, &:focus {
      color: $primary;
      background-color: $primary-light;
      border-color: $primary;
    }
  }

  &.k-button-outline-primary,
  &.btn-primary-outline {
      color: $primary;
      border: 1px solid $primary;
      background-color: transparent;
      &:hover, &:focus {
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }
  }


  &.k-button-solid-secondary,
  &.btn-secondary {
      background-color: $secondary;
      border-color: $secondary;
      color: $white;
      &:hover, &:focus {
        color: $black;
        border-color: $secondary-hover;
        background-color: $secondary-hover;
      }
  }

  &.btn-light-secondary {
    background-color: $secondary-light;
    border-color: $secondary-light;
    color: $secondary;
    &:hover, &:focus {
      color: $white;
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  &.k-button-outline-secondary,
  &.btn-secondary-outline {
      color: $secondary;
  }


  &.k-button-solid-tertiary,
  &.btn-tertiary {
      background-color: $tertiary;
      border-color: $tertiary;
  }
  &.k-button-outline-tertiary,
  &.btn-tertiary-outline {
      color: $tertiary;
  }

  //Success
  &.k-button-solid-success,
  &.btn-success {
      background-color: $success;
      border-color: $success;
      &:hover, &:focus {
        color: $white;
        border-color: $success-hover;
        background-color: $success-hover;
      }
  }


  &.k-button-outline-success,
  &.btn-success-outline {
      color: $success;
      border: 1px solid $success!important;
      background-color: transparent;
      &:hover, &:focus {
        color: $white;
        background-color: $success;
        border-color: $success;
      }
  }

  &.btn-light-success {
    background-color: $success-light;
    border-color: $success-light;
    color: $success;
    &:hover, &:focus {
      color: $white;
      background-color: $success;
      border-color: $success;
    }
  }

  &.btn-light-success-reverse-light-only {
    background-color: $success;
    border-color: $success;
    color: $white;
    &:hover, &:focus {
      color: $success;
      background-color: $success-light;
      border-color: $success;
    }
  }

  //Info
  &.k-button-solid-info,
  &.btn-info {
      background-color: $info;
      border-color: $info-dark;
      &:hover, &:focus {
        color: $white;
        border-color: $info-hover;
        background-color: $info-hover;
      }
  }

  &.btn-light-info {
    background-color: $info-light;
    border-color: $info-light;
    color: $info;
    &:hover, &:focus {
      color: $white;
      background-color: $info;
      border-color: $info;
    }
  }

  &.k-button-outline-info,
  &.btn-info-outline {
      color: $info;
  }

  // Warning
  &.k-button-solid-warning,
  &.btn-warning {
      background-color: $warning;
      border-color: $warning;
      &:hover, &:focus {
        color: $white;
        border-color: $warning-hover;
        background-color: $warning-hover;
      }
  }

  &.btn-light-warning {
            background-color: $warning-light;
            border-color: $warning;
            color: $warning;
    &:hover, &:focus {
      color: $white;
      background-color: $warning;
      border-color: $warning;
    }
  }

  &.btn-light-warning-reverse-light-only {
    background-color: $warning;
    border-color: $warning;
    color: $white;
    &:hover, &:focus {
      color: $warning;
      background-color: $warning-light;
      border-color: $warning;
    }
  }

  &.k-button-outline-warning,
  &.btn-warning-outline {
      color: $warning;
  }

  // Danger/Error
  &.k-button-solid-error,
  &.btn-error {
      background-color: $error;
      border-color: $error;
      &:hover, &:focus {
        color: $white;
        border-color: $error-hover;
        background-color: $error-hover;
      }
  }

  &.btn-light-danger {
    background-color: $danger-light;
    border-color: $danger-light;
    color: $danger;
    &:hover, &:focus {
      color: $white;
      background-color: $danger;
      border-color: $danger;
    }
  }

  &.btn-light-danger-reverse-light-only {
    background-color: $danger;
    border-color: $danger;
    color: $white;
    &:hover, &:focus {
      color: $danger;
      background-color: $danger-light;
      border-color: $danger;
    }
  }


  &.k-button-outline-danger,
  &.btn-danger-outline {
      color: $danger;
      border: 1px solid $danger!important;
      background-color: transparent;
      &:hover, &:focus {
        color: $white;
        background-color: $danger;
        border-color: $danger;
      }
  }
  // Dark
  &.k-button-solid-dark,
  &.btn-dark {
      background-color: $dark;
      border-color: $dark;
      &:hover, &:focus {
        color: $white;
        border-color: $dark-hover;
        background-color: $dark-hover;
      }
  }

  &.btn-light-dark {
    background-color: $dark-light;
    border-color: $dark-light;
    color: $dark;
    &:hover, &:focus {
      color: $white;
      background-color: $dark;
      border-color: $dark;
    }
  }


  //Light
  &.k-button-solid-light,
  &.btn-light {
       background-color: #f8f5ff;
      border-color: $light-color;
      color: gray;
      &:hover, &:focus, &:hover:not(.btn-active), &:focus:not(.btn-active) {
        color: white;
        border-color: $light-color-hover;
        background-color: $primary;
        i {
          color: $primary;
        }
      }
  }

  &.btn-dark-only {
    background-color: $dark-btn-bg;
    border-color: $dark-btn-bg;
    color: $secondary-hover;
    &:hover, &:focus {
      background-color: $dark-btn-bg-hover;
      border-color: $dark-btn-bg-hover;
      color: $white;
    }
  }

  &.text-link {
    background-color: transparent;
    padding: 0 !important;
    border: none;
    color: initial;
    &:hover, &:focus {
      color: $primary;
    }
  }
}

.btn-delete 
{
  background: #F5F8FA;
  border-color: #F5F8FA;
  height: 61px;
  border-radius: 0px 5px 5px 0px;
  padding: 1px 15px 0px 0px;
  
  &:hover
  {
    background: #F5F8FA;
    border-color: #F5F8FA;
  }
  span
  {
    background:#fff;
    color:#F1416C;
    border:solid #F1416C 1px;
    height: 22px;
    width: 22px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
  }
}

.btn-play
{
  span
  {
    height: 22px;
    width: 22px;
    border-radius: 45px;
    background: #fff;
    border: #0d6efd solid 1px;
    color: #0d6efd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
  }
}