.text-primary {
    color: $primary !important;
}
.text-gray-600 {
    color: $gray-600 !important;
}
.text-gray-700 {
    color: $gray-medium !important;
}
.text-gray-800 {
    color: $gray-500 !important;
  }

.text-dark {
    color: $r-black !important;
}
.text-gray-400 {
    color: $secondary-hover !important;
}

.text-muted {
    color: $gray!important;
}

.text-blue-faded {
    color: $blue-faded !important;
}

h1, h2, h3, h4, h5, h6 {
    span {
        font-size: 12.0px;
    }
}
