.form-control {
  padding: 0.75rem 1rem;
}
.form-group {
  @include flex-combinator(row, wrap, flex-start, flex-start);
  margin-bottom: 1rem;
  font-family: $font-primary;
  .form-control {
    width: 100%;
    background-color: transparent;
    @include flex-combinator(row, wrap, center, flex-start);
    border: 0;
    position: relative;
    input {
      border-radius: 0;
      padding: 0.75rem 45px 0.75rem 1.5rem;
      width: 100%;
      height: 45px;
      border-width: 1px;
      box-shadow: none;
      background-color: $gray-lighter;
      border-color: $gray-lighter;
      color: $gray-medium;
      font-family: $font-primary;
    }
    .k-clear-value {
      position: absolute;
      right: 45px;
      z-index: 2;
      top: 0;
      height: 100%;
      transition: all 0.3s ease 0s;

      &:hover {
        color: $danger;
        transition: all 0.3s ease 0s;
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: calc(100% - 2px);
      z-index: 2;
      width: 45px;
      background-color: transparent;
      border: 0;
    }
  }
  button {
    &:focus,
    &:active {
      box-shadow: none;
      border: 0;
      background-color: transparent;
    }
  }
}

.form-group {
  &.custom-icon {
    position: relative;
    .form-control {
      input {
        padding-left: 40px;
      }
    }
    .field-icon {
      position: absolute;
      left: 0;
      top: 29px;
      z-index: 1;
      height: 45px;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      @include flex-combinator(row, wrap, center, center);
      &:focus,
      &:active {
        box-shadow: none;
        border: 0;
        background-color: transparent;
      }
    }
    &.help-icon {
      .field-icon {
        cursor: pointer;
        @extend .btn;
        @extend .btn-light;
        padding: 2px 0 0;
      }
    }
  }
  &.select-area {
    width: 100%;
    kendo-dropdownlist {
      width: 100%;
    }
  }
}

.relaygrid-form {
  .form-group {
    @include flex-combinator(row, wrap, flex-start, flex-start);
    margin-bottom: 1rem;
    label {
      margin: 0 0 0.3rem 0;
    }
    .form-control {
      border-radius: 0;
      padding: 0.75rem 45px 0.75rem 1.5rem;
      width: 100%;
      border-width: 1px;
      box-shadow: none;
      background-color: $gray-lighter;
      border-color: $gray-lighter;
      color: $gray-medium;
      font-family: $font-primary;
      &:not(textarea) {
        height: 45px;
      }
    }
  }
}
.k-input-inner {
  font-size: $font-size;
}
.k-form {
  .k-form-fieldset {
    position: relative;
    &.custom-icon {
      .k-input-inner,
      .k-dropzone {
        padding-left: 40px;
        height: 45px;
      }
      .field-icon {
        position: absolute;
        left: 0.75rem;
        top: 27px;
        background-color: transparent;
        border: 0;
        height: 45px;
        z-index: 2;
        @include flex-combinator(row, wrap, center, center);
      }
    }
  }
  .k-form-field {
    @include flex-combinator(column, wrap, flex-start, flex-start);
    margin: 0 0 1rem 0;
    > div {
      width: 100%;
    }
  }
  .k-input,
  .k-picker {
    width: 100%;
    background-color: $gray-lighter;
    border-color: $gray-lighter;
    color: $gray-medium;
    border-radius: 5px;
    &:focus {
      border-color: $gray-lighter;
      box-shadow: none;
    }
    .k-input-inner {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      border: 0;
      height: 45px;
      font-weight: normal;
      font-family: $font-primary;
      font-size: $font-size;
    }
    &.k-invalid,
    &.ng-invalid.ng-touched,
    &.ng-invalid.ng-dirty {
      border-color: $alert;
    }
  }
  .k-upload {
    background: transparent;
    border-color: $gray-lighter;
    border-radius: 4px;
    overflow: hidden;
    .k-dropzone {
      border: 0;
      color: $gray-medium;
    }
    .k-upload-button {
      background: transparent;
      border: 0;
      top: 9px;
      left: -9px;
      font-size: 1.1rem;
      color: $gray-medium;
      padding: 0;
      cursor: pointer;
      &:focus {
        box-shadow: none;
      }
    }
  }
  kendo-label {
    font-size: 1.15rem;
    font-weight: $medium;
    margin: 0 0 0.3rem 0;
    label {
      margin: 0 !important;
    }
  }
}

.k-textarea {
  .k-input-inner {
    font-weight: normal;
  }
}

.k-form-error {
  // position: absolute;
  bottom: -5px;
  margin-top: 5px;
  display: flex;
  font-size: 0.8rem;
  font-style: normal;
}

.k-floating-label-container {
  &.icon{
    &.k-empty {
      &:not(.k-focus) {
        > .k-label {
          left: 38px;
        }
      }
    }  
  }
}

.toggle-fieldset {
  position: relative;
  @include flex-combinator(row, nowrap, center, space-between);
  .k-form-field {
    width: calc(100% - 60px);
  }
  .k-form-fieldset {
    &.toggle-fieldset-btn {
      transform: translateY(-50%);
      margin: 0;
      width: 52px;
      margin-left: 15px !important;
      .form-title {
        display: none;
      }
      kendo-label {
        margin: 0 0.5rem 0 0;
      }
      .k-label {
        white-space: nowrap;
        font-size: 1.1rem;
      }
    }
  }
  .k-switch-md {
    width: 52px;
    height: 22px;
    .k-switch-track {
      @include flex-combinator(row, nowrap, center, space-between);
      width: 52px;
      height: 33px;
      padding: 0 6px 0;
      font-size: 12px;
    }
    .k-switch-thumb {
      @include sequre(16px);
    }
    .k-switch-label-on,
    .k-switch-label-off {
      position: initial;
      display: flex;
      top: initial;
      transform: translate(0);
    }
    &.k-switch-off {
      border-color: #f1416c;
      .k-switch-label-on {
        display: none;
      }
    }
    &.k-switch-on {
      .k-switch-label-off {
        display: none;
      }
    }
    .k-switch-label-off {
      margin-left: auto;
    }
  }
}

.numberonly {
  .k-spin-button {
    display: none;
  }
}

.relaygrid-form {
  .k-form-fieldset {
    &[class*="-6"] {
      &:nth-child(odd) {
        padding-right: 0.75rem;
      }
      &:nth-child(even) {
        padding-left: 0.75rem;
      }
    }
  }
}

.k-input,
.k-picker {
  border-radius: 5px;
  &:focus,
  &.k-focus {
    box-shadow: none;
  }
}

// .k-form .k-input:focus,
// .k-form .k-picker:focus {
//     border: 0;
// }

.k-input.k-invalid:focus-within,
.k-input.ng-invalid.ng-touched:focus-within,
.k-input.ng-invalid.ng-dirty:focus-within,
.k-input:focus,
.k-input.k-focus {
  box-shadow: none;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.k-custom-input {
  position: relative;
  .hosted-field-label {
    position: absolute;
    margin: 0;
    top: 14px;
    left: 40px;
    color: $black-1;
    transition: all 0.3s linear 0s;
  }
  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 48px;
    @include flex-combinator(row, wrap, center, center);
  }
  .hosted-field {
    background-color: $gray-lighter;
    border: 1px solid $gray-lighter;
    color: $gray-medium;
    border-radius: 4px;
    position: relative;
    height: 48px;
    iframe {
      position: relative;
      z-index: 1;
    }
    input {
      padding: 1.6rem 1.5rem 3px 40px;
    }
    &.braintree-hosted-fields-invalid {
      border-color: $alert;
    }
    &.braintree-hosted-fields-focused,
    &.braintree-hosted-fields-valid,
    &.braintree-hosted-fields-invalid {
      .hosted-field-label {
        top: 3px;
        transition: all 0.3s linear 0s;
      }
    }
  }
}

.custom-select-btn {
  background-color: $light-color;
  border-color: $light-color;
  color: $gray-medium;
  font-family: $font-primary;
  font-size: $font-size;
  padding: 0.7rem 1rem 0.7rem 1.5rem;
  border-radius: 4px;

  &:hover,
  &:focus {
    background-color: $gray-400;
    border-color: $gray-400;
    box-shadow: none;
  }
}

.k-menu-popup {
  .k-menu-group {
    background-color: transparent;
    .k-item {
      .k-menu-link {
        padding: 0.8rem 0.7rem;
        padding-inline-end: 4rem;
        &:hover,
        &:focus {
          background-color: $gray-lighter;
          color: $primary;
        }
      }
      &.k-focus,
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.custom-upload {
  .k-upload {
    .k-upload-files {
      flex: 1;
      position: relative;
      z-index: 4;
      border: 0;
      padding: 0;
      li {
        width: 100%;
        height: 42px;
        padding: 0 0.8rem 0 10px;
        background-color: $light-color;
        @include flex-combinator(row, nowrap, center, space-between);
        border: 1px solid transparent;

        // &.k-file-error {
        //   border-color: $danger;
        // }
      }
    }
    .k-actions {
      @include flexbox();
      height: 45px;
      background-color: $light-color;
      padding: 0;
      position: relative;
      .k-button {
        &.k-clear-selected {
          @extend .btn-light-danger;
        }
        &.k-upload-selected {
          @extend .btn-light-primary;
          margin-left: 0.5rem;
        }
      }
    }

    .k-dropzone {
      .k-dropzone-hint {
        @include flex-combinator(row, nowrap, center, space-between);
        padding: 0 0.8rem;
        font-family: $font-primary;
        font-size: 0px;
        &:before {
          content: "file has not been uploaded.";
          font-size: 1.1rem;
          font-family: $font-primary;
          margin-top: -2px;
        }
      }
    }
  }
  .form-group {
    height: 47px;
    &:focus {
      border: 0;
      box-shadow: none;
    }
    &.label_height {
      .form-group {
        background: #f8f9fa;
      }
    }
  }
  .k-dropzone {
    width: 100%;
    position: absolute;

    .k-upload-button {
      left: initial;
      top: initial;
      font-size: 0 !important;
      line-height: 0;
      min-height: auto;
      @include sequre(33px);
      padding-top: 3px;
      // padding-left: 2px;
      cursor: pointer;
      border: 2px solid;
      color: #0d6efd;
      &:hover {
        background: $primary;
        color: $white;
      }
      input {
        font-family: $font-primary;
        font-size: 100%;
        cursor: pointer;
      }
      span {
        position: relative;
        font-size: 0 !important;
        &:before {
          content: "\e09a";
          font-family: "Font Awesome 6 Duotone";
          font-size: 8px;
          position: absolute;
        }
        &:after {
          content: "\e590\e590";
          font-family: "Font Awesome 6 Duotone";
          font-size: 8px;
        }
      }
    }
  }

  &.upload-image {
    // .k-dropzone {
    //   .k-upload-button {
    //     span {
    //       &:after {
    //         content: '\f03e\f03e';
    //       }
    //     }
    //   }
    // }
    .k-upload {
      .k-upload-files {
        li {
          &:before {
            // content: '\f03e\f03e';
          }
        }
      }
    }
  }
}

.k-form {
  .k-form-fieldset {
    &.custom-icon {
      &.custom-upload {
        .field-icon {
          left: 0;
          top: 0;
        }
      }
    }
  }
}
.k-upload .k-upload-files .k-file:last-child:not(:first-child) {
  border-width: 0;
}

.mp3Player {
  @include flex-combinator(row, nowrap, center, space-between);
  border: 1px solid $gray-lighter;
  background-color: $gray-lighter;
  padding: 30px;
  height: 42px;
  width: 100%;
  border-radius: 4px 0px 0px 4px;
  .fileName {
    flex: 1;
    margin-bottom: 0px;
  }
  .field-icon,
  p {
    color: $gray-medium;
  }
}

.k-checkbox-md {
  @include rounded(18px, 4px);
  background-color: transparent;
}

.k-picker-solid {
  border: 1px solid $gray-lighter;
  background-color: $gray-lighter;
  border-radius: 5px;

  &:hover,
  &:focus {
    border: 1px solid $gray-lighter;
    background-color: $gray-lighter;
  }

  .k-input-inner {
    box-shadow: none;
    background-color: transparent;
    border: none;
    border-radius: 5px;
  }
}

.iconpos {
  .custom-icon {
    .k-dropzone {
      padding-left: 14px !important;
    }
  }
}

.k-dropdownlist {
  height: 45px !important;
  width: 100% !important;
}