body {
  &.theme-dark {
    .r-tabs {
      .k-content {
        background-color: transparent;
        // border-color: $light-color;
        // border-style: dashed;
      }

      .k-tabstrip-items {
        .k-item {
          background-color: $primary-dark;
          color: $primary;

          .icon {
            color: $primary;
          }

          &.k-state-active,
          &.k-state-selected,
          &:hover,
          &:focus {
            background-color: $primary;
            border-color: $primary;
            color: $white;
            transition: all 0.3s ease 0s;

            .icon {
              color: $white;
            }

            &:focus {
              background-color: $primary;
            }
          }
        }
      }
    }
  }
}
