.footer {
    background-color: #f9f9f9;
    font-size: 14px;
    color:#506690;
    a, span
    {
        color:#506690;
    }
    .menu-item 
    {
        border-right:#A5A9BA solid 1px;
        line-height: normal;
        padding: 0px 15px;
        &:last-child
        {
            border-right: none;
            padding-right: 0px;
        }
        .menu-link
        {
            color:#506690;
            font-size: 14px;
            padding: 0px;
            &:hover
            {
                color:#506690;
            }
        }
    }
}
