.k-upload {
  .k-upload-files {
    .k-file {
      padding: 0.2rem;
    }
  }

  .k-actions {
    display: none;
  }
}
