body {
    &.theme-dark {
        .card {
            background-color: $purple-dark;
            .card-header {
                background-color: $purple-dark;
                color: $white;
            }

            .card-footer {
                border-top: 1px solid $purple-50;
            }
        }
    }
}