.k-notification-group {
    margin: 0 !important;
    left: inherit!important;
    right: 0;
    width: calc(100% - 266px);
    z-index: 5;
    .k-notification-container {
        margin: 0;
        width: 100%;
        .k-notification {
            width: 100%;
            height: 74px;
            @include flex-combinator(row, wrap, center, center);
        }
    }
}